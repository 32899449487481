/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 13:53:24
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-01 23:42:32
 * @Description: file content
 */
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Box, Typography } from "@material-ui/core";
import bgImage1 from "../../images/canada-passport.jpg";
import bgImage2 from "../../images/IMG_5358_64.jpg";
import Container from "@material-ui/core/Container";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useStore from "../../zustand/zustand";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import axios from "axios";
import { LocationSearchingTwoTone } from "@material-ui/icons";
import loadingPage from "../LoadingPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bookConsultationBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.6rem 1.5rem 0.6rem 0.8rem",
    margin: "0.4rem 2rem 0.4rem 0",
    background:
      "linear-gradient(90deg,#C9A850 5% ,#FAFAFA 15% , #FAFAFA 45% ,#C9A850 60%);",
    backgroundSize: "750px 200px",
    borderRadius: "2px",
    transition: " 0.3s ease-out",
    backgroundPosition: "150px",
    "&:hover": {
      backgroundPosition: "300px",
      color: "#343434",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "space-around",
    minHeight: "300px",
    backgroundColor: "#F4F4F4",
    alignItems: "center",
  },
  title: {
    color: "#FAFAFA",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
  text: {
    color: "#FAFAFA",
  },
}));
function Example(props) {
  const [services, setServices] = React.useState([]);
  const langCode = useStore((s) => s.language);
  const classes = useStyles();
  const loadServices = async () => {
    if(!langCode){
      return
    }
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        lang: langCode,
        service_type: 76,
      },
    });
    if (result.status == 200) {
      setServices(result.data);
    }
  };
  React.useEffect(() => {
    loadServices();
  }, [langCode]);
  var items = [
    {
      title: {
        en: "CANADA BUSINESS IMMIGRATION",
        vi: "ĐẦU TƯ ĐỊNH CƯ CANADA",
      },
      text: {
        en: "OWNER OPERATOR/LMIA PROGRAM",
        vi: "TỰ LÀM CHỦ DOANH NGHIỆP - OWNER OPERATOR",
      },
      bgImage: bgImage1,
    },
    {
      title: {
        en: "CANADA BUSINESS IMMIGRATION",
        vi: "ĐẦU TƯ ĐỊNH CƯ CANADA",
      },
      text: {
        en: "THE BRITISH COLUMBIA PROVINCIAL NOMINEE PROGRAM (BCPNP) ",
        vi: "ĐỀ CỬ TỈNH BANG BRITISH COLUMBIA DIỆN DOANH NH N (BCPNP)",
      },
      bgImage: bgImage1,
    },
    {
      title: {
        en: "CANADA BUSINESS IMMIGRATION",
        vi: "ĐẦU TƯ ĐỊNH CƯ CANADA",
      },
      text: {
        en:
          "SASKATCHEWAN IMMIGRANT NOMINEE PROGRAM (SINP) – ENTREPRENEUR CATEGORY",
        vi: "ĐỀ CỬ TỈNH BANG SASKATCHEWAN DIỆN DOANH NH N (SINP)",
      },
      bgImage: bgImage1,
    },
    {
      title: {
        en: "CANADA BUSINESS IMMIGRATION",
        vi: "ĐẦU TƯ ĐỊNH CƯ CANADA",
      },
      text: {
        en: "YUKON BUSINESS NOMINEE PROGRAM (YBNP)",
        vi: "ĐỀ CỬ TỈNH BANG YUKON DIỆN DOANH NH N (YBNP)",
      },
      bgImage: bgImage2,
    },
  ];
  if (services.length == 0) {
    return (
      <div className={classes.loadingContainer}>
        <Box>
          {" "}
          Loading...
          <loadingPage />
        </Box>
      </div>
    );
  }
  return (
    <Carousel 
    interval={7000}
    navButtonsAlwaysVisible>
      {services.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  console.log(props);
  const langCode = useStore((s) => s.language);
  const classes = useStyles();
  return (
    <Box
      style={{
        backgroundImage: props.item.acf.carsoul_image?`url(${props.item.acf.carsoul_image.sizes["2048x2048"]})`:"none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "40vh",
      }}
    >
      <Container>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={5}>
            <Box
              style={{
                backgroundColor: "rgba(201,168,80,0.85)",
                borderRadius: "3px",
                padding: "2rem",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
            >
              <Typography className={classes.title}>
                {props.item.title.rendered}
              </Typography>
              <Typography className={classes.text}>
                {props.item.acf.description}
              </Typography>
              <ButtonBase
                component={Link}
                to={`/${langCode}/service/${props.item.slug}`}
                style={{
                  borderRadius: "5px",
                  border: "3px solid white",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <Typography className={classes.text}>SEE MORE</Typography>
              </ButtonBase>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default Example;
