/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-30 00:14:14
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import RecentNews from "./recentNews";

import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../../langaguage";
import homeBanner from "../../images/home-banner-2000-1333.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";

import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "../LoadingPage";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",

    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  blogTitle: {
    color: "#3c3950",
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#5F727F",
    marginBottom: "2rem",

    "&:link": {
      color: "#5F727F" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:active": {
      color: "#5F727F" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:visited": {
      color: "#5F727F" /* blue colors for links too */,
      textDecoration: "inherit",
    },
  },
  blogDate: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: "3rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
}));

export default function ProminentAppBar(props) {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  let { id } = useParams();

  const [news, setNews] = React.useState([]);
  const [recentNews, setRecentNews] = React.useState([]);
  const langCode = useStore((s) => s.language);

  const getRecentNews = async () => {
    if (!langCode) {
      return;
    }
    console.log("fetching news with lang ", langCode);
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        per_page: 10,
        blog_type: 1505,
      },
    });

    if (result.status == 200) {
      setRecentNews(result.data);
    }
  };

  React.useEffect(() => {
    getRecentNews();
  }, [langCode]);
  //loading page

  return (
    <>
      <Box style={{ marginTop: "1rem", marginBottom: "3rem" }}>
        {recentNews.length > 0 ? (
          recentNews.map((v, i) => {
            return (
              <>
                <Box style={{display:"flex",justifyContent:"flex-start"}}>
                  <Box
                    style={{
                      backgroundImage: `url(${
                        v.acf.cover_image && v.acf.cover_image.sizes.large
                      })`,
                      minWidth: "100px",
                      minHeight: "100px",
                      width:"100px",
                      // maxWidth: "50vw",
                      // margin: "0 auto",
                      marginBottom: "2rem",
                      marginRight:"1rem",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></Box>
                  <Box style={{ marginBottom: "1.5rem" }}>
                    <Typography
                      className={classes.blogTitle}
                      // style={{ fontSize: "1rem" }}
                      component={Link}
                      to={`/${langCode}/blog/${v.slug}`}
                    >
                      {v.title.rendered}
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "200px",
            }}
          >
            <Loading />
          </Box>
        )}
      </Box>
    </>
  );
}
// export default Navbar;
