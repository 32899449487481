/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-23 12:03:09
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import logoH from "../images/immgration-logo-2000-566.png";
import vi from "../images/vietnam.png";
import ca from "../images/canada.png";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../langaguage";
import homeBanner from "../images/home-banner.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import logoV from "../images/immgration-logo-vertical-2000-1163.png";
import Carousel from "./component/carousel";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";
import parallaxImage from "../images/IMG_5358_64.jpg";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "./LoadingPage";
import oppertunitiesIcon from "../images/oppertunities-icon.jpeg";
import connectionIcon from "../images/connections-icon.jpeg";
import trustIcon from "../images/trust-icon.jpeg";
import optimaztionIcon from "../images/optimization-icon.jpeg";
import LogoComponment from "../pages/component/logo";
import Banner from "./component/Banner";
import clsx from 'clsx';
import {Helmet} from "react-helmet";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",
    marginTop: "4rem",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    "&:hover": {
      backgroundColor: "rgba(201,168,80,0.9)",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
    minHeight: "500px",
  },
  bannerTitle: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerSubTitle: {
    fontSize: "1.7rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  serviceTitle: {
    color: "#3c3950",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  serviceText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
  linkRemoveStyle: {
    "&:link": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:active": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:visited": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:hover": {
      color: "#C9A850" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  fontL: {
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  fontM: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  fontS: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0rem",
    },
  },
  fontXS: {
    fontSize: "1.0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  Parallax: {
    backgroundImage: `url("${parallaxImage}")`,
    height: "45vh",
    minHeight: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "65% 50%",
    backgroundPositionX: "65%",
    backgroundPositionY: "50%",

    backgroundSize: "cover",
    OBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("sm")]: {
      backgroundAttachment: "scroll",
    },
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const [services, setServices] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const iconSet = [
    oppertunitiesIcon,
    connectionIcon,
    trustIcon,
    optimaztionIcon,
  ];
  const langCode = useStore((s) => s.language);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getService = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        lang: langCode,
        service_type: 1830,
        per_page: 100,
      },
    });
    if (result.status == 200) {
      setServices(result.data);
    }
  };
  const getNews = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        per_page: 3,
      },
    });
    if (result.status == 200) {
      setNews(result.data);
    }
  };
  React.useEffect(() => {
    getService();
    getNews();
  }, [langCode]);
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
               
              <meta name="description" content={lang.seo_home[langCode]}/>
            </Helmet>
     
      {/* <Box className={classes.bannerContianer}>
        <Container>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              style={{
               
                color: "#FAFAFA",
                fontWeight: 500,
                display: "inline-block",
                textAlign: "center",
              }}
              className={classes.bannerTitle}
            >
              {lang.home_banner_text[langCode]}
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              style={{
               
                color: "#FAFAFA",
                fontWeight: 500,
                display: "inline-block",
                textAlign: "center",
              }}
              className={classes.bannerSubTitle}
            >
              {lang.hone_banner_subtext[langCode]}
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <ButtonBase
              component={Link}
              to={`/${langCode}/contact`}
              className={classes.contactUsBtn}
            >
              <Typography
                style={{
                  margin: "0.5rem 1rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              >
                {lang.home_banner_contact[langCode]}
              </Typography>
              <MailOutlineIcon
                style={{
                  margin: "0.5rem 0.5rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              ></MailOutlineIcon>
            </ButtonBase>
          </Box>
        </Container>
      </Box> */}
      <Banner
        image={homeBanner}
        title={lang.home_banner_text[langCode]}
        subTitle={lang.hone_banner_subtext[langCode]}
        displayBtn={true}
        contact={lang.home_banner_contact[langCode]}
      />
      <Container>
     
        <Box
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "2rem 0",
          }}
        >
          <LogoComponment />
        </Box>
      </Container>
      <Carousel />
      <Container style={{ paddingBottom: "2rem" }}>
        <Grid container>
          <Grid xs={12} md={4}>
            <Box className={classes.centerContainer}>
              <Box>
                <Typography className={classes.serviceTitle}>
                  {lang.home_perma_service[langCode]}
                </Typography>
                <ul className={classes.serviceText}>
                  {services.length > 0 ? (
                    services
                      .filter((v) => {
                        if (v.service_type) {
                          return v.service_type.indexOf(73) > -1;
                        }
                        return false;
                      })
                      .map((v) => {
                        return (
                          <li className={classes.serviceList}>
                            <Typography
                              className={classes.linkRemoveStyle}
                              component={Link}
                              to={`/${langCode}/service/${v.slug}`}
                            >
                              {v.title.rendered}
                            </Typography>
                          </li>
                        );
                      })
                  ) : (
                    <Box>
                      <Loading />
                    </Box>
                  )}
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box className={classes.centerContainer}>
              <Box>
                <Typography className={classes.serviceTitle}>
                  {lang.home_temp_service[langCode]}
                </Typography>
                <ul className={classes.serviceText}>
                  {services.length > 0 ? (
                    services
                      .filter((v) => {
                        if (v.service_type) {
                          return v.service_type.indexOf(74) > -1;
                        }
                        return false;
                      })
                      .map((v) => {
                        return (
                          <li className={classes.serviceList}>
                            <Typography
                              className={classes.linkRemoveStyle}
                              component={Link}
                              to={`/${langCode}/service/${v.slug}`}
                            >
                              {v.title.rendered}
                            </Typography>
                          </li>
                        );
                      })
                  ) : (
                    <Box>
                      <Loading />
                    </Box>
                  )}
                </ul>
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} md={4}>
            <Box className={classes.centerContainer}>
              <Box>
                <Typography className={classes.serviceTitle}>
                  {lang.home_citizen_service[langCode]}
                </Typography>
                <ul className={classes.serviceText}>
                  {services.length > 0 ? (
                    services
                      .filter((v) => {
                        if (v.service_type) {
                          return v.service_type.indexOf(75) > -1;
                        }
                        return false;
                      })
                      .map((v) => {
                        return (
                          <li className={classes.serviceList}>
                            <Typography
                              className={classes.linkRemoveStyle}
                              component={Link}
                              to={`/${langCode}/service/${v.slug}`}
                            >
                              {v.title.rendered}
                            </Typography>
                          </li>
                        );
                      })
                  ) : (
                    <Box>
                      <Loading />
                    </Box>
                  )}
                </ul>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div
        className={classes.Parallax}
        // style={{
        //   backgroundImage: `url("${parallaxImage}")`,
        //   height: "45vh",

        //   backgroundRepeat:"no-repeat",
        //   // backgroundPosition: "65% 50%",
        //   // backgroundPositionX: "65%",
        //   // backgroundPositionY: "50%",

        //   backgroundSize: "cover",
        //   OBackgroundSize:  "cover",
        //   MozBackgroundSize:  "cover",
        //   WebkitBackgroundSize:  "cover",
        //   backgroundAttachment: "scroll",
        // }}
      />
      <Container>
        <Box className={classes.centerContainerFixed}>
          <Box>
            <Typography
              className={classes.fontM}
              style={{
                color: "#3c3950",
                margin: "2.5rem 0 1rem 0",
              }}
            >
              {" "}
              {lang.home_about_us[langCode]}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.centerContainerFixed}>
          <Box>
            <Typography
              className={classes.fontS}
              style={{ color: "#3c3950", margin: "1rem 0" ,fontWeight:600}}
            >
              {" "}
              {lang.home_about_us_sub[langCode]}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.centerContainerFixed}>
          <Box>
            <Typography
              className={classes.fontXS}
              style={{ color: "#5F727F", margin: "1rem 0" }}
            >
              {" "}
              {lang.home_about_us_text[langCode]}
            </Typography>
          </Box>
        </Box>
        <Grid container>
          {lang.home_selling_point.map((v, i) => {
            return (
              <Grid
                item
                xs={12}
                md={3}
                className={classes.centerContainerFixed}
              >
                <Box style={{ padding: "1rem" }}>
                  <Box
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <img
                      src={iconSet[i]}
                      style={{
                        width: "200px",
                        height: "200px",
                        marginBottom: "1rem",
                      }}
                    />
                  </Box>
                  <Typography
                    className={classes.fontS}
                    style={{
                      color: "#3c3950",
                      margin: "1rem 0",
                    }}
                  >
                    {" "}
                    {v.title[langCode]}
                  </Typography>
                  <Typography
                    className={classes.fontXS}
                    style={{
                      color: "rgb(95, 114, 127)",
                      margin: "1rem 0",
                    }}
                  >
                    {" "}
                    {v.text[langCode]}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Box className={classes.centerContainerFixed}>
          <Box>
            <Typography
              className={classes.fontM}
              style={{
                fontWeight: 500,
                color: "#C9A850",
                margin: "1rem 0",
              }}
            >
              {" "}
              {lang.home_need_help[langCode]}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.centerContainerFixed}>
          <Box>
            <Typography
              className={classes.fontS}
              style={{
                color: "#3C3950",
                margin: "1rem 0 2rem 0",
              }}
            >
              {" "}
              {lang.home_need_help_phone[langCode]}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box style={{ background: "#3C3950" }}>
        <Box className={classes.centerContainerFixed}>
          <Box>
            <Typography
              className={classes.fontM}
              style={{
                color: "#FAFAFA",
                margin: "3rem 0 3rem 0",
              }}
            >
              {" "}
              {lang.home_review_title[langCode]}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Container>
        <Grid container style={{ padding: "3rem 0 3rem 0" }}>
          {lang.home_reviews.map((v) => (
            <>
              <Grid item xs={12} md={8}>
                <Box style={{ display: "flex", marginBottom: "2rem" }}>
                  <FormatQuoteIcon
                    className={classes.fontL}
                    style={{
                      transform: "rotate(-180deg)",
                      color: "#7F8B96",
                    }}
                  />
                  <Typography
                    className={classes.fontS}
                    style={{ color: "#3C3950" }}
                  >
                    {v.review[langCode]}
                  </Typography>

                  <FormatQuoteIcon
                    className={classes.fontL}
                    style={{ color: "#7F8B96" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  style={{
                    display: "flex",
                    // flexDirection: "row-reverse",
                    marginBottom: "2rem",
                  }}
                >
                    <Box
                    style={{
                      margin: "auto 2rem auto 1rem",
                      width: "6rem",
                      height: 0,
                      border: "1px solid #7F8B96 ",
                    }}
                  ></Box>
                  <Typography
                    className={classes.fontS}
                    style={{ color: "#3C3950",width:"100%" }}
                  >
                    {v.author[langCode]}
                  </Typography>
                
                </Box>
              </Grid>
            </>
          ))}
        </Grid>
      </Container>
      <Box style={{ background: "#EAECED" }}>
        <Container>
          <Box className={classes.centerContainerFixed}>
            <Box>
              <Typography
                style={{
                  fontSize: "2rem",
                  color: "#3C3950",
                  margin: "3rem 0 3rem 0",
                }}
              >
                {lang.home_lastest_news[langCode]}
              </Typography>
            </Box>
          </Box>
          <Grid container>
            {news.length > 0 ? (
              news.map((v) => {
                if (v.title)
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ padding: "0 2rem 0 2rem", marginBottom: "2rem" }}
                    >
                      <Box className={classes.centerContainerFixed}>
                        <Box
                          style={{
                            backgroundImage: `url(${
                              v.acf.cover_image && v.acf.cover_image.sizes.large
                            })`,
                            minWidth: "250px",
                            minHeight: "250px",
                            width: "70%",

                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            
                          }}
                          component={Link}
                        to={`/${langCode}/blog/${v.slug}`}
                        ></Box>
                      </Box>
                      <Box className={classes.centerContainerFixed}>
                        <Box>
                          <Typography
                            style={{
                              fontSize: "1.2rem",
                              color: "#3C3950",
                              margin: "1.5rem 0 0.5rem 0",
                              fontWeight:600
                            }}

                            component={Link}
                        to={`/${langCode}/blog/${v.slug}`}
                        className={classes.linkRemoveStyle}
                          >
                            {v.title.rendered}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.centerContainerFixed}>
                        <Box>
                          <Typography
                            style={{
                              fontSize: "1rem",
                              color: "#3C3950",

                              margin: "1rem 0 1rem 0",
                            }}
                            component={Link}
                        to={`/${langCode}/blog/${v.slug}`}
                        className={classes.linkRemoveStyle}
                          >
                            {v.acf.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                      
                        component={Link}
                        to={`/${langCode}/blog/${v.slug}`}
                        style={{fontSize:"0.8rem"}}
                        className={classes.serviceText}
                      >
                        {lang.see_more[langCode]}
                      </Typography>
                    </Grid>
                  );
              })
            ) : (
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  padding: "0 2rem 0 2rem",
                  marginBottom: "4rem",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Loading />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      <Box style={{ height: "200px" }}>
        <Box
          className={classes.centerContainerFixed}
          style={{ alignItems: "center", height: "100%" }}
        >
          <ButtonBase
            className={classes.contactUsBtn2}
            style={{ margin: "auto" }}
            component={Link}
            to={`/${langCode}/contact`}
          >
            {lang.home_contact_us_btn[langCode]}
          </ButtonBase>
        </Box>
      </Box>
    </>
  );
}
// export default Navbar;
