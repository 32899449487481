/*
 * @Author: Dongchen Xie
 * @Date: 2020-11-01 16:04:29
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-01-20 13:40:55
 * @Description: file content
 */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import useStore from "../../zustand/zustand";
import Logo from "../../images/immgration-logo-2000-566.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import lang from "./surveyLang";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const surveyOpen = useStore((s) => s.surveyOpen);
  const setSurveyOpen = useStore((s) => s.setSurveyOpen);
  const langCode = useStore((s) => s.language);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const classes = useStyles();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getSteps() {
    return ["Personal Information", "Language", "Education", "Employment"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return "Select campaign settings...";
      case 1:
        return "What is an ad group anyways?";
      case 2:
        return "This is the bit I really care about!";
      case 3:
        return "This is the bit I really care about!";
      default:
        return "Unknown stepIndex";
    }
  }
  return (
    <div>
      <Dialog
        open={surveyOpen}
        TransitionComponent={Transition}
        maxWidth={"lg"}
        keepMounted
        onClose={() => {
          setSurveyOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{
            width: "800px",
            maxWidth: "100%",
            background: "linear-gradient(90deg,#3C3950 30% ,#A9B3BA 95%)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={4}>
                <img src={Logo} style={{ maxHeight: "40px", width: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#FAFAFA",
                    fontSize: "1.2rem",
                    fontWeight: 500,
                  }}
                >
                  {lang.title[langCode]}
                </Typography>
              </Grid>
              <Grid item xs={0} sm={3}></Grid>
            </Grid>
            <IconButton
              onClick={() => {
                setSurveyOpen(false);
              }}
              aria-label="upload picture"
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          ></Box>
        </DialogTitle>
         
          {langCode=='en'&&<iframe
            src="https://wordpress.northwesternimmigration.com/free-initial-assessment"
            width="100%"
            height="500px"
            minHeight="50vh"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>}
          {langCode=='vi'&&<iframe
            src="https://wordpress.northwesternimmigration.com/mien-phi-danh-gia-so-bo"
            width="100%"
            height="500px"
            minHeight="50vh"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>}
  
      </Dialog>
    </div>
  );
}
