/*
 * @Author: Dongchen Xie
 * @Date: 2020-11-14 21:03:19
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-22 18:49:04
 * @Description: file content
 */
import React from "react";
import I11 from "./logoImage/1-1.png";
import I12 from "./logoImage/1-2.png";
import I13 from "./logoImage/1-3.png";
import I14 from "./logoImage/1-4.png";
import I15 from "./logoImage/1-5.png";
import I16 from "./logoImage/1-6.png";
import I17 from "./logoImage/1-7.png";
import I18 from "./logoImage/1-8.png";
import I19 from "./logoImage/1-9.png";
import I110 from "./logoImage/1-10.png";
import I111 from "./logoImage/1-11.png";
import I112 from "./logoImage/1-12.png";
import I21 from "./logoImage/2-1.png";
import I22 from "./logoImage/2-2.png";
import I23 from "./logoImage/2-3.png";
import I24 from "./logoImage/2-4.png";
import I25 from "./logoImage/2-5.png";
import I26 from "./logoImage/2-6.png";
import I27 from "./logoImage/2-7.png";
import I28 from "./logoImage/2-8.png";
import I29 from "./logoImage/2-9.png";
import I210 from "./logoImage/2-10.png";
import I211 from "./logoImage/2-11.png";
import biglogo from "./logoImage/logo.png";
import $ from "jquery";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const images1 = [I11, I12, I13, I14, I15, I16, I17, I18, I19, I110, I111, I112];
const images11 = [
  I11,
  I12,
  I13,
  I14,
  I15,
  I16,
  I17,
  I18,
  I19,
  I110,
  I111,
  I112,
];
const images2 = [I21, I22, I23, I24, I25, I26, I27, I28, I29, I210, I211];
const logoHeight = 291;
const line1 = 84;
const line2 = 137;
const scaleLg = 0.6;
const scaleMd = 0.45;
const scaleSm = 0.3;
const useStyles = makeStyles((theme) => ({
  logo: {
    opacity: 1,
    height: `${logoHeight * scaleLg}px`,
    [theme.breakpoints.down("md")]: {
      height: `${logoHeight * scaleMd}px`,
    },
    [theme.breakpoints.down("md")]: {
      height: `${logoHeight * scaleSm}px`,
    },
  },
  line1: {
    position: "relative",
    height: `${line1 * scaleLg}px`,
    [theme.breakpoints.down("md")]: {
      height: `${line1 * scaleMd}px`,
    },
    [theme.breakpoints.down("md")]: {
      height: `${line1 * scaleSm}px`,
    },
  },
  line2: {
    position: "relative",
    height: `${line2 * scaleLg}px`,
    [theme.breakpoints.down("md")]: {
      height: `${line2 * scaleMd}px`,
    },
    [theme.breakpoints.down("md")]: {
      height: `${line2 * scaleSm}px`,
    },
  },
}));
export default function Logo() {
  const classes = useStyles();
  const [trigger,setTrigger]=React.useState({})
  // React.useEffect(() => {
  //   console.log("!!!!!!!!");
  //   var $window = $(window);
  //   $(window).resize(execute);

  //   $window.on("scroll", execute);
  //   execute()
  //   function execute() {
  //     if ($(window).width() < 992) {
  //       return;
  //     }
  //     var position = $window.scrollTop();
  //     console.log("position", position);
  //     //line1
  //     for (let i = 1; i < 13; i++) {
      
  //       try {
  //         if (position >= $(`#1-${i}`).offset().top - 800) {
            
  //             $(`#1-${i}`)
  //               .animate(
  //                 {
  //                   opacity: "1",
  //                   left: "0",
  //                 },
  //                 1000)
  //               .delay(400 * i);
           
  //         } else {
  //           $(`#1-${i}`)
  //             .stop(true)
  //             .animate(
  //               {
  //                 opacity: "0",
  //                 left: "-50",
  //               },
  //               100
  //             )
  //             .delay(400 * i);
  //         }
  //       } catch (err) {}
  //       //line2
  //       for (let i = 1; i < 12; i++) {
          
  //         try {
  //           if (position >= $(`#2-${i}`).offset().top - 800) {
  //             //   console.log(`#2-${i}`);
  //             if ($(window).width() > 992) {
  //               $(`#2-${i}`)
  //                 .animate(
  //                   {
  //                     opacity: "1",
  //                     left: "0",
  //                   },
  //                   1000
  //                 )
  //                 .delay(3500 + 100 * i);
  //             }
  //           } else {
  //             $(`#2-${i}`)
  //               .stop(true)
  //               .animate(
  //                 {
  //                   opacity: "0",
  //                   left: "-50",
  //                 },
  //                 100
  //               )
  //               .delay(3500 + 100 * i);
  //           }
  //         } catch (err) {}
  //       }
  //     }
  //     //logo
  //     if (position >= $(`#logo`).offset().top - 550) {
        
  //       $(`#logo`)
  //         .animate(
  //           {
  //             opacity: "1",
              
  //           },
  //           1000
  //         )
  //         .delay(4000);
  //     } else {
  //       $(`#logo`)
  //         .stop(true)
  //         .animate(
  //           {
  //             opacity: "0",
            
  //           },
  //           100
  //         )
  //         .delay(4000);
  //     }
      
  //   }
  // }, []);
  return (
    <div id="logo-contianer" style={{paddingTop:"1rem",paddingBottom:"1rem"}}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div className={classes.logo}>
          <img className={classes.logo} id="logo" src={biglogo} />
        </div>
      </div>
      <div>
        {images1.map((v, i) => {
          return <img className={classes.line1} src={v} id={`1-${i + 1}`} />;
        })}
      </div>
      <div>
        {images2.map((v, i) => {
          return <img className={classes.line2} src={v} id={`2-${i + 1}`} />;
        })}
      </div>
    </div>
  );
}
