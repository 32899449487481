/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 18:10:23
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-01 16:07:02
 * @Description: file content
 */
import create from "zustand";

export default create((set) => ({
  language: null,
  setLanguage: (lang) => set((s) => ({ language: lang })),
  enNews: null,
  setEnNews: (url) => {
    console.log("en set to",url);
    set((s) => ({ enNews: url }));
  },
  viNews: null,
  setViNews: (url) => {
    console.log("vi set to",url);
    set((s) => ({ viNews: url }));
  },
  surveyOpen:false,
  setSurveyOpen:(bool)=>{
    set((s)=>({surveyOpen:bool}))
  },
  navOpen:false,
  setNavOpen:(bool)=>{
    set((s)=>({navOpen:bool}))
  },
}));
