/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-21 00:14:30
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-01 20:16:50
 * @Description: file content
 */
import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  // typography: {
  //   fontFamily: [
  //     "-apple-system",
  //     "BlinkMacSystemFont",
  //     '"Segoe UI"',
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(","),
  // },
  status: {
    danger: "#666666",
  },
  palette: {
    primary: {
      light: "#C9A850",
      main: "#C9A850",
      dark: "#C9A850",
      contrastText: "#fff",
    },
    secondary: {
      light: "#3C3950",
      main: "#3C3950",
      dark: "#3C3950",
      contrastText: "#fff",
    },
  },
});
