/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-01-04 10:38:13
 * @Description: file content
 */
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import logoH from "../images/immgration-logo-2000-566.png";
import vi from "../images/vietnam.png";
import ca from "../images/canada.png";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../langaguage";
import homeBanner from "../images/about-us-banner.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import logoV from "../images/immgration-logo-vertical-2000-1163.png";
import Carousel from "./component/carousel";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";
import parallaxImage from "../images/IMG_5358_64.jpg";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "./LoadingPage";
import NeedSomeHelp from "./component/needSomeHelp";
import AboutUsPic from "../images/about-us-2000-1321.jpg";
import Banner from "./component/Banner";
import {Helmet} from "react-helmet";
import Profile from "../widget/Profile";
import img1 from "../images/1.png";
import img2 from "../images/2.png";
import img3 from "../images/3.png";
import img4 from "../images/4.png";
import { map } from "jquery";
const profilesPic=[img1,img2,img3,img4]
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",
    marginTop: "4rem",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    "&:hover": {
      backgroundColor: "rgba(201,168,80,0.9)",
    },
  },
  bannerContianer: {
minHeight:"500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerSubTitle: {
    fontSize: "1.7rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  serviceTitle: {
    color: "#3c3950",
    fontSize: "1.5rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
  linkRemoveStyle: {
    "&:link": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:active": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:visited": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
  },
  fontL: {
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  fontM: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  fontS: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0rem",
    },
  },
  fontXS: {
    fontSize: "1.0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  Parallax: {
    backgroundImage: `url("${AboutUsPic}")`,
    height: "45vh",
    minHeight:"300px",
    backgroundRepeat: "no-repeat",
   backgroundPosition:"center center",

    backgroundSize: "cover",
    OBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("sm")]: {
      backgroundAttachment: "scroll",
    },
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const [services, setServices] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const langCode = useStore((s) => s.language);
  const [openTeamDetails,setOpenTeamDetails]=useState(-1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openDetail=(index)=>{
    if(index==openTeamDetails){
      setOpenTeamDetails(0)
    }else{
      setOpenTeamDetails(index)
    }
  }
  const getService = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        lang: langCode,
        per_page: 100,
      },
    });
    if (result.status == 200) {
      setServices(result.data);
    }
  };
  const getNews = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        per_page: 3,
      },
    });
    if (result.status == 200) {
      setNews(result.data);
    }
  };
  //   React.useEffect(() => {
  //     getService();
  //     getNews();
  //   }, [langCode]);
  return (
    <>
    <Helmet>
            <meta charSet="utf-8" />

            <meta name="description" content={lang.seo_about[langCode]} />
          </Helmet>
       <Banner
        image={homeBanner}
        title={lang.about_us_banner_title[langCode]}
        // subTitle={lang.hone_banner_subtext[langCode]}
        displayBtn={true}
        contact={lang.home_banner_contact[langCode]}
      />

      <Container style={{ paddingBottom: "2rem" }}>
        <Box>
          {lang.about_us_sections.map((v) => {
            return (
              <Box style={{ margin: "2rem 0" }}>
                <Typography
                  className={classes.fontM}
                  style={{
                    margin: "2rem 0",
                    textAlign: "center",
                    color: "#3C3950",
                    fontWeight: 500,
                  }}
                >
                  {v.title[langCode]}
                </Typography>
                <Typography
                  className={classes.fontXS}
                  style={{ whiteSpace: "pre-line", color: "5F727F" }}
                >
                  {v.text[langCode]}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Typography
                  className={classes.fontM}
                  style={{
                    margin: "2rem 0",
                    textAlign: "center",
                    color: "#3C3950",
                    fontWeight: 500,
                  }}
                >
                  Our Team
                </Typography>
        <Grid container>
       
         {profilesPic.map((v,i)=>{
            return(
            <Grid item xs={3}> 
              <Profile index={i} pic={v} name={lang.team_members[i].name[langCode]} bg={lang.team_members[i].bg[langCode]} title={lang.team_members[i].title[langCode]} setOpenTeamDetails={setOpenTeamDetails}/>
            </Grid>)
         })}
         {openTeamDetails>=0&&
         <Grid item xs={12}  
         onClick={()=>{setOpenTeamDetails(-1)}}
         style={{
          borderRadius: "5px",
          border: "2px solid rgb(201,168,80)",
          padding: "2rem",
        }}
        className={classes.fontXS}
        >
   {lang?.team_members[openTeamDetails]?.intro[langCode]}
   </Grid>}
         
        </Grid>

       
      </Container>
      <div
       className={classes.Parallax}
      />
      <Container>
        <NeedSomeHelp />
      </Container>
    </>
  );
}
// export default Navbar;
