/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-18 16:47:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-10 12:08:24
 * @Description: file content
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if(String(this.props.location.pathname).startsWith("/en/service")||String(this.props.location.pathname).startsWith("/vi/service")){
			
			return
		}
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)