/*
 * @Author: Dongchen Xie
 * @Date: 2021-01-11 11:01:42
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-02-02 21:01:26
 * @Description: file content
 */
import React from 'react'
import useStore from "../../zustand/zustand";
import $ from "jquery"
import ScriptTag from 'react-script-tag';
import {
    Grid,
    TextField,
    FormGroup,
    Box,
    ButtonBase,
    Container,
    makeStyles,
    Typography,
  } from "@material-ui/core";


import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    background:"white",
    backgroundColor:"white",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  
}));
export default function EmailSubs() {
    // React.useEffect(() => {
    //     console.log("!!!!dfsafsaf!!!!")
    //     const script = document.createElement('script');
      
    //     script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    //     script.async = true;
      
    //     document.body.appendChild(script);
    //     // console.log("!!!!")
    //     // return () => {
    //     //   document.body.removeChild(script);
    //     // }
    //   }, []);
    const classes=useStyles()
   
	const langCode = useStore((s) => s.language);
    return (
        <div>
    
<div id="mc_embed_signup">
<form action="https://gmail.us7.list-manage.com/subscribe/post?u=a4936dc9a19a54e5dbef4d086&amp;id=c6959c2107" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
        <Box style={{display:"flex",justifyContent:"center",marginBottom:"1rem"}}>
        <MailIcon style={{color:"rgba(95, 114, 127)",fontSize:"5rem"}}/>
        </Box>
        
	<Typography style={{display:"flex",justifyContent:"center",marginBottom:"1rem"}}>{langCode=="en"?"Stay up to date with the latest news on Canada Immigration.":"Theo dõi các tin tức mới nhất về Định cư Canada."}</Typography>

<div className="mc-field-group">
	
	<InputBase style={{backgroundColor:"white",borderRadius:"4px", width:"100%", padding:"0.5rem"}} type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder={langCode=="en"?"Enter your email here ":" Nhập email tại đây"} required/>
</div>
<input name="group[79232]" value={langCode=="en"?"1":"2"} style={{display: "none"}} class="REQ_CSS" id="mce-group[79232]"></input>
<input type="text" value={langCode} name="MMERGE3" style={{display:"none"}} id="mce-MMERGE3"></input>
	<div id="mce-responses" className="clear">
		<div className="response" id="mce-error-response" style={{display:"none"}}></div>
		<div className="response" id="mce-success-response" style={{display:"none"}}></div>
	</div>
    <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_a4936dc9a19a54e5dbef4d086_c6959c2107" tabindex="-1" value=""/></div>
    <div className="clear">
        <Box style={{display:"flex",justifyContent:"center",marginTop:"1.5rem",marginBottom:"1.5rem" , borderBottom:"grey 1px solid"}}>

        
    <ButtonBase  name="subscribe" id="mc-embedded-subscribe"
                style={{
                  margin: "0 0 3rem 0",
                  background:
                    "linear-gradient(90deg, rgba(95, 114, 127) 26.56%, rgba(95, 114, 127) 100%)",
                  borderRadius: 2,
                }}
                type="submit"
              > <Typography
              style={{
                padding: "0.6rem 1.5rem 0.6rem 1.5rem",
                fontSize: "1rem",
                fontWeight: 400,
                color: "#FFFFFF",
              }}
            >
              {langCode=="en"?"Subscribe":"Đăng ký"}
            </Typography>
                 </ButtonBase></Box></div>
    </div>
</form>
</div>
< ScriptTag type="text/javascript" src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'/>        </div>
    )
}
