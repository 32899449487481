/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-10 01:05:27
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../../langaguage";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bookConsultationBtn: {
    color: "white",
    fontWeight: 400,
    padding: "0.6rem 1.5rem 0.6rem 1.5rem",
    margin: "0.4rem 0rem 3rem 0",
    fontSize: "1.2rem",
    background:
      "linear-gradient(90deg,#C9A850 15% ,#FAFAFA 40% , #FAFAFA 45% ,#C9A850 60%);",
    backgroundSize: "750px 200px",
    borderRadius: "2px",
    transition: " 0.3s ease-out",
    backgroundPosition: "60px",
    "&:hover": {
      backgroundPosition: "200px",
      color: "#343434",
    },
  },
  sectionTitle: {
    fontSize: "2rem",
    fontWeight: 500,
    color: "#C9A850",
    margin: "2rem 0 2rem 0",
  },
  sectionText: {
    color: "rgb(115, 131, 143)",
  },
  sectionSupportText: {
    margin: "2rem 0 2rem 0",
    color: "#C9A850",
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const langCode = useStore((s) => s.language);
  const setSurveyOpen = useStore((s) => s.setSurveyOpen);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box
        style={{
          margin: "3rem 0 4rem 0",
          textAlign: "center",
          fontSize: "3rem",

          verticalAlign: "middle",
        }}
      >
        <Grid container justify="center">
          <Grid xs={12} md={8} lg={4}>
            <Box>
              <Typography className={classes.sectionTitle}>
                {lang.need_some_help_title[langCode]}
              </Typography>
              <Typography className={classes.sectionText}>
                {lang.need_some_help_text[langCode]}
              </Typography>
              <Typography className={classes.sectionSupportText}>
                {lang.need_some_help_get_start[langCode]}
              </Typography>
              <Button onClick={setSurveyOpen.bind(true)} className={classes.bookConsultationBtn}>
                {lang.need_some_help_bookbtn[langCode]}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
// export default Navbar;
