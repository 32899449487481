/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-13 02:15:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-13 02:31:17
 * @Description: file content
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import orange from "@material-ui/core/colors/orange";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function CircularIndeterminate() {
  const classes = useStyles();
    console.log(orange[500])
  return (
    <div className={classes.root}>
      <CircularProgress
       
        color="primary"
      />
    </div>
  );
}
