/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-01 09:18:30
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import useStore from "../../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../../langaguage";
import homeBanner from "../../images/blog-banner.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 400,
    padding: "0.8rem 1.5rem 0.8rem 1.5rem",
    fontFamily: "'Source Serif Pro', serif",
    // border: "3px solid #FAFAFA",
    background: "rgba(255,255,255,0.5)",
    backgroundSize: "750px 300px",
    marginTop: "4rem",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1.2rem",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    "&:hover": {
      backgroundColor: "rgba(201,168,80,0.9)",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    // background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontFamily: "'Source Serif Pro', serif",
    color: "#FAFAFA",
    fontWeight: 700,
    fontSize: "2.0rem",
    textAlign: "center",
    marginBottom:"0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  bannerSubTitle: {
    fontFamily: "'Source Serif Pro', serif",
    color: "#FAFAFA",
    fontWeight: 400,
    fontSize: "1.2rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  blackBox: {
    position: "absolute",
    bottom: "2rem",
    backgroundColor: "rgba(0,0,0,0.4)",
    minHeight: "5rem",
    minWidth: "60vw",
    left: "50%",
    maxWidth: "95vw",
    transform: "translateX(-50%)",
    padding: "2rem",

    [theme.breakpoints.down("md")]: {
      width: "75vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  blogTitle: {
    color: "#3c3950",
    fontSize: "1.8rem",
    fontFamily: "'Libre Baskerville', serif",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  blogDate: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: "3rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.8rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
}));
export default function ProminentAppBar(props) {
  const classes = useStyles();
  const langCode = useStore((s) => s.language);

  return (
    <>
      <Box
        className={classes.bannerContianer}
        style={{
          background: `url(${
            props.image ? props.image : homeBanner
          }) no-repeat  center`,
          position: "relative",
          minHeight: "500px",
          // background: `url(${homeBanner}) no-repeat  center`,
          backgroundSize: "cover",
          height: "75vh",
        }}
      >
        <Container style={{}}>
          <Box className={classes.blackBox}>
            {props.title&& props.displayBtn && (
              <Typography className={classes.bannerTitle}>
                {props.title}
              </Typography>
            )}
             {props.title&& !props.displayBtn && (
              <Box style={{margin:"3rem 0rem"}}>
                <Typography className={classes.bannerTitle}>
                  {props.title}
                </Typography>
              </Box>
            )}
            {props.subTitle  && (
              <Typography className={classes.bannerSubTitle}>
                {props.subTitle}
              </Typography>
            )}
           
            {props.displayBtn && (
              <Box style={{ display: "flex", justifyContent: "space-around" }}>
                <ButtonBase
                  component={Link}
                  to={`/${langCode}/contact`}
                  className={classes.contactUsBtn}
                >
                  {props.contact}{" "}
                  <MailOutlineIcon style={{ margin: "0 0 0.1rem 1rem" }} />
                </ButtonBase>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
// export default Navbar;
