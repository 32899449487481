/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-10 00:49:53
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import useStore from "./../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "./../langaguage";
import homeBanner from "./../images/home-banner-2000-1333.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";

import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "./LoadingPage";
import moment from "moment";
import SingleBlog from "../pages/SingleBlog";
import SideBar from "./component/SideBar";
import Pagination from "@material-ui/lab/Pagination";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from '@material-ui/core/FormControlLabel';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",

    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerContianer: {
minHeight:"500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  resultTitle: {
    fontSize: "2.5rem",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
    color: "#3C3950",
    marginBottom: "2rem",
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  blogTitle: {
    color: "#3c3950",
    fontSize: "1.8rem",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  blogDate: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: "3rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ProminentAppBar(props) {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  let { id } = useParams();
  let query = useQuery();
  let history = useHistory();
  const [news, setNews] = React.useState([]);
  const [recentNews, setRecentNews] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalResult,setTotalResult]=React.useState(0)
  const setEnNews = useStore((s) => s.setEnNews);
  const setViNews = useStore((s) => s.setViNews);
  const [selectedValue, setSelectedValue] = React.useState("blogs");

  const handleChange = (event) => {
      console.log(event.target.value)
    setSelectedValue(event.target.value);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const langCode = useStore((s) => s.language);

  const getRecentNews = async () => {
    if(!query.get("query")){
      history.goBack()
      return
    }
    if(!langCode){
      return
    }
    console.log("fetching news with lang ", langCode);
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/"+selectedValue,
      params: {
        lang: langCode,
        page:page,
        per_page:3,
        search: query.get("query") ? query.get("query") : undefined,
      },
    });

    if (result.status == 200) {
      if (result.data.length == 0) {
        alert("no matched result");
        history.goBack();
        return;
      }
      setRecentNews(result.data);
      console.log(result);
      setTotalPage(result.headers["x-wp-totalpages"]);
      setTotalResult(result.headers["x-wp-total"])
    }else{}
  };

  React.useEffect(() => {
    setRecentNews([]);
    setTotalPage(1)
    setTotalResult(0)
    getRecentNews();
  }, [langCode, query.get("query"),selectedValue,page]);
 React.useEffect(()=>{
     setPage(1)
     
 },[selectedValue])
  //loading page
  if (query.get("id")) {
    console.log("single blog");
    return <SingleBlog id={query.get("id")} />;
  } else {
    setEnNews(null);
    setViNews(null);
  }
  return (
    <>
      <Container
        style={{ marginTop: "3rem", marginBottom: "3rem", minHeight: "30vh" }}
      >
        <Typography className={classes.resultTitle}>
          Showing results for : &nbsp;
          <a style={{ color: "#5F727F" }}>{query.get("query")}</a>
        </Typography>
        <FormControlLabel
          value="services"
          control={
            <Radio
              checked={selectedValue === "services"}
              onChange={handleChange}
            
              color="primary"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          }
          label="Services"
        />
        <FormControlLabel
          value="blogs"
          control={
            <Radio
              checked={selectedValue === "blogs"}
              onChange={handleChange}
             
              color="primary"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          }
          label="Blogs"
        />
        <Box style={{margin:"2rem 0"}}>
            <Typography style={{fontSize:"1.2rem",color:"#3C3950"}}>
                Total Results: {totalResult}
            </Typography>
        </Box>
        <Grid container justify="space-around">
          <Grid item xs={12}>
            {recentNews.length > 0 ? (
              recentNews.map((v, i) => {
                return (
                  <Box style={{ marginBottom: "3rem" }}>
                    <Typography
                      className={classes.blogTitle}
                      style={{ fontSize: "1.2rem" }}
                    >
                      {v.title.rendered}
                    </Typography>
                    <Typography
                      className={classes.blogDate}
                      style={{ marginBottom: "1.2rem" }}
                    >
                      {moment(v.modified).format("MMMM Do YYYY")} by
                      Northwestern Immigration
                    </Typography>
                    <Grid container justify="space-between">
                      <Grid item xs={12} md={4}>
                        <Box
                          style={{
                            backgroundImage: `url(${
                              v.acf.cover_image && v.acf.cover_image.sizes.large
                            })`,
                            minWidth: "250px",
                            minHeight: "250px",
                            maxWidth: "50vw",
                            margin: "0 auto",
                            marginBottom: "2rem",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></Box>
                      </Grid>
                      <Grid item item xs={12} md={7}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <Box>
                            <Typography style={{ textOverflow: "ellipsis" }}>
                              {v.acf.description}
                            </Typography>
                          </Box>

                          <Typography
                            component={Link}
                            to={`/${langCode}/${selectedValue.slice(0, -1)}/${v.slug}`}
                            className={classes.serviceText}
                            style={{
                              marginBottom: "1rem",
                              textAlign: "right",
                              marginTop: "1rem",
                              marginBottom: "4rem",
                            }}
                          >
                            {lang.readmore[langCode]} ...
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  height: "200px",
                }}
              >
                <Loading />
              </Box>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-around">
          <Pagination
            count={totalPage}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Container>
    </>
  );
}
// export default Navbar;
