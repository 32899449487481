/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 11:09:02
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-09-30 14:03:07
 * @Description: file content
 */

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import logoWhite from "../images/logo-white.png";
import bg from "../images/gold-bgc-footer-2000-1279.jpg";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import facebook from "../images/facebook.png";
import skype from "../images/skype.png";
import ig from "../images/instagram.png";
import whatsapp from "../images/whatsapp.png";
import footImage1 from "../images/ICCRC.png";
import footImage2 from "../images/capic_accpi.png";
import footImage3 from "../images/RCIC.png";
import youtube from "../images/iconfinder_youtube_834723.png";
import zalo from "../images/zalo-icon.png";
import lang from "../langaguage";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  socialMediaIcon: {
    width: "2.5rem",
    height: "2.5rem",
  },
  iconTop: {
    borderTop: "1.2px solid #C9C9C9",
    [theme.breakpoints.down("md")]: {
      borderTop: "none",
    },
  },
}));
export default function Footer() {
  const classes = useStyles();
  const langCode = useStore((s) => s.language);
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <IconButton
        onClick={() => {
          window.scroll({
            top: 0,
            behavior: 'smooth'
          });
        }}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "30px",
          backgroundColor: "#C9A850 ",
        }}
      >
        <Tooltip title="Back to Top">
          <ArrowDropUpIcon className={classes.socialMediaIcon} />
        </Tooltip>
      </IconButton>

      <Container>
        <Box style={{ display: "flex", justifyContent: "space-around" }}>
          <img
            src={logoWhite}
            style={{
              height: "130px",
              width: "130px",
              maxWidth: "35vw",
              margin: "1rem auto 1rem auto",
            }}
          />
        </Box>
        <Grid container justify="center" >
          <Grid xs={12} sm={6} item >
            <Box className={classes.centerContainer}>
              <Box>
                <Box>
                  <Typography
                    style={{
                      color: "#FAFAFA",
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      marginBottom: "1.2rem",
                    }}
                    className={classes.text}
                  >
                    {lang.footer_head_office[langCode]}
                  </Typography>
                  <Typography
                    style={{
                      color: "#FAFAFA",
                      fontWeight: 400,
                      fontSize: "1rem",
                    }}
                  >
                    2025 Willingdon Avenue, Floor 9,
                    <br />
                    Burnaby, British Columbia, Canada
                    <br />
                    V5C 0J3{" "}
                    <LocationOnIcon
                      style={{
                        color: "#FAFAFA",
                        fontWeight: 400,

                        fontSize: "1.2rem",
                      }}
                    />
                  </Typography>
                </Box>
                <Box style={{ marginTop: "1.5rem" }}>
                  <Typography
                    style={{
                      color: "#FAFAFA",
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      marginBottom: "1.2rem",
                    }}
                    className={classes.text}
                  >
                    {lang.footer_vancouver_office[langCode]}
                  </Typography>
                  <Typography
                    style={{
                      color: "#FAFAFA",
                      fontWeight: 400,
                      fontSize: "1rem",
                      marginBottom: "1.5rem",
                    }}
                  >
                    666 Burrad Street, Floor 5
                    <br />
                    Vancouver, British Columbia, Canada
                    <br />
                    V6C 3P6{" "}
                    <LocationOnIcon
                      style={{
                        color: "#FAFAFA",
                        fontWeight: 400,
                        fontSize: "1.2rem",
                      }}
                    />
                  </Typography>
                </Box>
                <Box style={{ marginTop: "1.5rem" }}>
                  <Typography
                    style={{
                      color: "#FAFAFA",
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      marginBottom: "1.2rem",
                    }}
                    className={classes.text}
                  >
                    {lang.contact_us_ho_office[langCode]}
                  </Typography>
                  <Typography
                    style={{
                      color: "#FAFAFA",
                      fontWeight: 400,
                      fontSize: "1rem",
                      marginBottom: "1.5rem",
                    }}
                  >
                   {lang.contact_us_ho_office_address.street[langCode]}
                    <br />
                    {lang.contact_us_ho_office_address.city[langCode]}
                    <br />
                    {lang.contact_us_ho_office_address.country[langCode]}
                    <LocationOnIcon
                      style={{
                        color: "#FAFAFA",
                        fontWeight: 400,
                        fontSize: "1.2rem",
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Grid xs={12} sm={6} item></Grid>
          </Grid>

          <Grid xs={12} sm={6} alignItems="center" style={{height:"100%"}}>
            <Box className={classes.centerContainer}>
              <Box>
                <Typography
                  style={{
                    color: "#FAFAFA",
                    fontWeight: 500,
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem",
                  }}
                  className={classes.text}
                >
                  {lang.footer_connect_with_us[langCode]}
                </Typography>
                <Typography
                  style={{
                    color: "#FAFAFA",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  Hotline:
                </Typography>
                <Typography
                  style={{
                    color: "#FAFAFA",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  +1 (778)-926-9869 (In Canada)
                  <br/>
                  (+84) 888 369 369 (in Vietnam)
                </Typography>
                <Typography
                  style={{
                    marginTop: "1rem",
                    color: "#FAFAFA",
                    fontWeight: 500,
                    fontSize: "1.1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  Email
                </Typography>
                <Typography
                  style={{
                    color: "#FAFAFA",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  info@northwesternimmigration.com
                </Typography>
                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                  <IconButton href="https://www.facebook.com/NorthwesternImmigration">
                    <Tooltip title="Facebook">
                      <img src={facebook} className={classes.socialMediaIcon} />
                    </Tooltip>
                  </IconButton>

                  <IconButton href="skype:live:.cid.353113844e8e3d49?chat">
                    <Tooltip title="Skype">
                      <img src={skype} className={classes.socialMediaIcon} />
                    </Tooltip>
                  </IconButton>
                  <IconButton href="https://www.instagram.com/northwesternimmigration/">
                    <Tooltip title="Instagram">
                      <img src={ig} className={classes.socialMediaIcon} />
                    </Tooltip>
                  </IconButton>
                  <IconButton href="https://wa.me/17789269869">
                    <Tooltip title="WhatsApp">
                      <img src={whatsapp} className={classes.socialMediaIcon} />
                    </Tooltip>
                  </IconButton>
                  <IconButton href="#">
                    <Tooltip title="Youtube">
                      <img src={youtube} className={classes.socialMediaIcon} />
                    </Tooltip>
                  </IconButton>
                  <IconButton href="https://chat.zalo.me/?phone=+17789269869">
                    <Tooltip title="Zalo">
                      <img src={zalo} className={classes.socialMediaIcon} />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            style={{ borderTop: "1.2px solid #C9C9C9" }}
            xs={0}
            md={2}
           
          >
          
             
          </Grid>
          <Grid style={{ borderTop: "1.2px solid #C9C9C9" }} xs={12} md={8}  container
            alignContent="flex-end">
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "0 1rem 0 1rem",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  margin: "1rem 0",
                  fontSize: "0.8rem",
                  fontWeight: "300",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Copyright © 2021 Northwestern Global Immigration. All rights
                reserved.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={2} className={classes.iconTop}>
            <Box style={{ display: "flex", flexDirection: "row-reverse",alignItems:"center" }}>
              <img
                src={footImage2}
                style={{ height: "90px", margin: "1rem" }}
              />
              <img
                src={footImage3}
                style={{ height: "120px", margin: "1rem" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
