/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-01-31 23:53:35
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../../langaguage";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import logoV from "../../images/immgration-logo-vertical-2000-1163.png";

import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";

import Loading from "../LoadingPage";
import SearchBox from "./searchbox";
import facebook from "../../images/facebook.png";
import skype from "../../images/skype.png";
import ig from "../../images/instagram.png";
import whatsapp from "../../images/whatsapp.png";
import RecentNews from "./recentNewsSideBar"
import EmailSub from "./EmailSubs"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",

    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },

  bannerTitle: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  serviceTitle: {
    color: "#3c3950",
    fontSize: "1.5rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
  socialMediaIcon: {
    width: "2rem",
    height: "2rem",
  },
  link: {
    "&:link": {
      color: "#767676"
    },
    "&:visited": {
      color: "#343434"
    }
  }
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const [services, setServices] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const langCode = useStore((s) => s.language);

  return (
    <>
      <Box
        style={{
          backgroundColor: "rgb(233, 229, 217)",
          border: "1px solid #767676",
          borderRadius: "3px",
          padding: "2rem",
          marginBottom: "3rem"
        }}
      >
        <EmailSub/>
        <SearchBox />
        <Typography style={{ color: "#3C3950", textAlign: "center", fontSize: "1.8rem", margin: "1rem 0", fontWeight: 500 }}>{lang.sidebar_join_us[langCode]}</Typography>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton href="https://www.facebook.com/NorthwesternImmigration">
            <Tooltip title="Facebook">
              <img src={facebook} className={classes.socialMediaIcon} />
            </Tooltip>
          </IconButton>
          <IconButton href="skype:+17789269869?chat">
            <Tooltip title="Skype">
              <img src={skype} className={classes.socialMediaIcon} />
            </Tooltip>
          </IconButton>
          <IconButton href="https://www.instagram.com/northwesternimmigration/">
            <Tooltip title="Instagram">
              <img src={ig} className={classes.socialMediaIcon} />
            </Tooltip>
          </IconButton>
          <IconButton href="https://wa.me/17789269869">
            <Tooltip title="WhatsApp">
              <img src={whatsapp} className={classes.socialMediaIcon} />
            </Tooltip>
          </IconButton>
        </Box>
        <Typography>
          {lang.sidebar_text[langCode]}
        </Typography>
        <Typography style={{ color: "#3C3950", textAlign: "center", fontSize: "1.4rem", margin: "1rem 0" }}>{lang.sidebar_trending[langCode]}</Typography>
        <RecentNews />
        <hr />

        <Typography style={{ color: "#3C3950", textAlign: "center", fontSize: "1.2rem", margin: "1rem 0" }} >{lang.sidebar_need_help[langCode]}</Typography>
        <Typography style={{ color: "#5F727F", textAlign: "center", fontSize: "1rem", margin: "1rem 0" }}>
          {lang.sidebar_message1[langCode]}<Typography className={classes.link} component={Link} to={`/${langCode}/contact`}>{lang.sidebar_link[langCode]}</Typography>
        </Typography>
        <Typography style={{ color: "#5F727F", textAlign: "center", fontSize: "1rem", margin: "1rem 0" }}>{lang.sidebar_or[langCode]}</Typography>
        <Typography style={{ color: "#5F727F", textAlign: "center", fontSize: "1rem", margin: "1rem 0" }}>{lang.sidebar_message2[langCode]}</Typography>
      </Box>
    </>
  );
}
// export default Navbar;
