import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Grid from "@material-ui/core/Grid"
import lang from '../langaguage';
import useStore from "../zustand/zustand"
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '80%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  fontL: {
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  fontM: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  fontS: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0rem",
    },
  },
  fontXS: {
    fontSize: "1.0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
}));

export default function Profile(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const langCode = useStore((s) => s.language);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
      <Grid container style={{padding:"10px"}}>
          <Grid items xs={12} lg={12} style={{padding:"10px"}}>
          <div style={{marginBottom:"5px",display:"flex",justifyContent:"center",width:"100%"}}>
          <div src={props.pic} style={{backgroundImage: `url("${props.pic}")`,maxHeight:"15vw",maxWeight:"15vw",height:"300px",width:"300px",backgroundPosition:"50% 10%",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}/>
             </div>
            
            <div className={classes.bannerTitle} style={{textAlign:"center",marginBottom:"15px",marginTop:"15px",display:"flex",justifyContent:"center",width:"100%"}}>
                <div className={classes.fontS} style={{borderBottom:"3px solid #C9A850"}}>
                {props.name}
                </div>    
             </div>
             <div >

             </div>
             <div className={classes.fontXS} style={{textAlign:"center",marginTop:"10px",marginBottom:"5px",display:"flex",justifyContent:"center",width:"100%"}}>
                <div>
                {props.bg}
                </div>
             </div>
             <div  className={classes.fontXS} style={{textAlign:"center",marginBottom:"5px",display:"flex",justifyContent:"center",width:"100%"}}>
                <div>
                {props.title}
                </div>
             </div>
             <div   className={classes.fontXS} style={{marginBottom:"5px",display:"flex",justifyContent:"center",width:"100%"}}>
                <div className={classes.fontXS} onClick={()=>{props.setOpenTeamDetails(props.index)}} style={{color:"#C9A850"}}>
                {lang.readmore[langCode]}
                </div> 
             </div>
          </Grid>
      </Grid>
  );
}
