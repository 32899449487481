/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 12:22:26
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-11-01 19:55:34
 * @Description: file content
 */
const lang = {
  title: {
    en: "Free Initial Assessment",
    vi: "Miễn phí đánh giá sơ bộ",
  },
  sectionATitle: {
    en: "Principal Applicant Personal Information",
    vi: "Thông tin cá nhân Đương đơn",
  },
  fullName: {
    en: "Full name",
    vi: "Họ và tên",
  },
  dob: {
    en: "Ngày sinh (MM-DD-YYYY)",
    vi: "Date of Birth (MM-DD-YYYY)",
  },
  yes: {
    en: "Yes",
    vi: "Có",
  },
  no: {
    en: "No",
    vi: "Không",
  },
  nationality: {
    en: "Nationality",
    vi: "Quốc tịch",
  },
  sex: {
    en: "Sex",
    vi: "Giới tính",
  },
  mailing: {
    en: "Mailing Address",
    vi: "Địa chỉ liên lạc",
  },
  residentAddressSame: {
    en: "Full name",
    vi: "Họ và tên",
  },
  streetNum: {
    en: "Street number",
    vi: "Số nhà",
  },
  streetName: {
    en: "Street name",
    vi: "Tên đường",
  },
  province: {
    en: "Province or State",
    vi: "Tỉnh",
  },
  city: {
    en: "City",
    vi: "Thành phố",
  },
  district: {
    en: "District",
    vi: "Quận",
  },
  conutry: {
    en: "Country",
    vi: "Quốc gia",
  },
  phone: {
    en: "Cell phone",
    vi: "Điện thoại",
  },
  email: {
    en: "Email",
    vi: "Email",
  },
  prSubmitted: {
    en: "Have you ever submitted Canadian Permanent Resident application?",
    vi: "Đã từng nộp hồ sơ xin Định cư tại Canada chưa?",
  },
  whichPRcategory: {
    en: "Which category?",
    vi: "Bạn nộp hồ sơ theo diện nào?",
  },
  beenInCanada: {
    en: "Have you ever been in Canada before?",
    vi: "Đã từng đến Canada chưa?",
  },
  statusInCanada: {
    en: "Your status in Canada",
    vi: "Họ và tên",
  },
  statusExpiry: {
    en: "Full name",
    vi: "Họ và tên",
  }
};
export default lang;
