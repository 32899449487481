/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-25 18:31:26
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-25 18:42:30
 * @Description: file content
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import useStore from "../../zustand/zustand"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useParams,
    useHistory,
    useRouteMatch,
  } from "react-router-dom";
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  
}));

export default function CustomizedInputBase() {
  const classes = useStyles();
  const langCode = useStore((s) => s.language);
  const history =useHistory()
  const search=(e)=>{
    e.preventDefault()
    console.log(e.target.query.value)
    history.push(`/${langCode}/search?query=${e.target.query.value}`)
    
  }
  return (
    <Paper component="form" onSubmit={search} className={classes.root} elevation={0}>
      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        name="query"
        className={classes.input}
        placeholder="Search in Website"
        inputProps={{ 'aria-label': 'Search in Website' }}
      />
      
     
     
    </Paper>
  );
}