/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-23 13:11:03
 * @Description: file content
 */
import React from "react";
import { useRouteMatch } from "react-router-dom";
import useStore from "../zustand/zustand";
import lang from "../langaguage";
import homeBanner from "../images/contact-us-banner.jpg";
import {
  Grid,
  TextField,
  FormGroup,
  Box,
  ButtonBase,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import LogoImage from "../images/immgration-logo-only.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import NeedSomeHelp from "../pages/component/needSomeHelp";
import Banner from "./component/Banner";
import {Helmet} from "react-helmet";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bookConsultationBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.6rem 1.5rem 0.6rem 0.8rem",
    margin: "0.4rem 2rem 0.4rem 0",
    background:
      "linear-gradient(90deg,#C9A850 5% ,#FAFAFA 15% , #FAFAFA 45% ,#C9A850 60%);",
    backgroundSize: "750px 200px",
    borderRadius: "2px",
    transition: " 0.3s ease-out",
    backgroundPosition: "150px",
    "&:hover": {
      backgroundPosition: "300px",
      color: "#343434",
    },
  },
  bannerTitle: {
    fontSize: "2.5rem",
    fontWeight:500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  headerTollFree: {
    color: "#5F727F",
  },
  fontL: {
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  fontM: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  fontS: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0rem",
    },
  },
  fontXS: {
    fontSize: "1.0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    isSent: null,
  });
  const langCode = useStore((s) => s.language);
  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };
  const submit = async (e) => {
    e.preventDefault();
    await axios({
      method: "post",
      url:
        "https://script.google.com/macros/s/AKfycbxSMuZpN0EkcdBFFyxeJnt7_pdaq7Y9apB3IIBimNjhdFYoJzWA/exec",
      data: {
        name: value.name,
        email: value.email,
        subject: value.subject,
        message: value.message,
        phone: value.phone,
      },
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          setValue({
            name: "",
            email: "",
            subject: "",
            message: "",
            isSent: true,
          });
        } else {
          setValue({ ...value, isSent: false });
        }
      })
      .catch((error) => {
        setValue({ ...value, isSent: false });
      });
  };
  const MarkerComponent = ({ text }) => (
    <>
      <Box>
        <LocationOnIcon
          style={{
            color: "#D80027",
            margin: "auto",
            fontSize: "2.5rem",
            transform: "translate(-50%,-50%)",
          }}
        />
      </Box>
    </>
  );

  return (
    <>
     <Helmet>
            <meta charSet="utf-8" />

            <meta name="description" content={lang.seo_contact[langCode]} />
          </Helmet>
      {/* <Box className={classes.bannerContianer}>
        <Container style={{ display: "flex", justifyContent: "space-around" }}>
          <Typography
            style={{
              margin: "20vh auto 20vh auto",
              color: "#FAFAFA",
            
              display: "inline-block",
              textAlign: "center",
            }}
            className={classes.bannerTitle}
          >
            {lang.contact_us_banner[langCode]}
          </Typography>
        </Container>
      </Box> */}
        <Banner
        image={homeBanner}
        title={lang.contact_us_banner[langCode]}
        // subTitle={lang.hone_banner_subtext[langCode]}
        // displayBtn={true}
        // contact={lang.home_banner_contact[langCode]}
      />
      <Container style={{ textAlign: "center", color: "#5F727F" }}>
        <Typography
          style={{
            margin: "3rem",
            fontWeight: 500,

            color: "#3C3950",
          }}
          className={classes.fontL}
        >
          {lang.contact_us_title[langCode]}
        </Typography>
        {/* <Typography
          style={{ margin: "3rem", fontWeight: 500, wordWrap: "break-word" }}
          className={classes.fontM}
        >
          {lang.contact_us_toll_free[langCode]}
        </Typography> */}
        <Typography
          style={{ margin: "3rem", fontWeight: 500, wordWrap: "break-word" }}
          className={classes.fontM}
        >
          {lang.contact_us_hotline[langCode]}
        </Typography>
        <Typography
          style={{ margin: "3rem", fontWeight: 500, wordWrap: "break-word" }}
          className={classes.fontM}
        >
          {lang.contact_us_email[langCode]}
        </Typography>
      </Container>
      <Container>
        <Grid container style={{ margin: "6rem 0 6rem 0" }}>
          <Grid item xs={12} md={6} style={{marginBottom:"2rem"}}>
            <Box style={{ margin: "0 0 2rem 0", width: "75%", height: 250 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyC4DOAR7kQUB2SrZphojdR-slpt_laeFqs",
                }}
                defaultCenter={{
                  lat: 49.26,
                  lng: -123.0032905,
                }}
                defaultZoom={14}
              >
                <MarkerComponent
                  lat={49.266001}
                  lng={-123.0032905}
                  text="Head Office"
                />
              </GoogleMapReact>
            </Box>
            <Box>
              <Typography
                style={{
                  margin: "0 0 2rem 0",
                  fontWeight: 500,
                 
                  color: "#5F727F",
                }}
                className={classes.fontM}
              >
                {lang.contact_us_head_office[langCode]}
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                2025 Willingdon Avenue, Floor 9
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                Burnaby, British Columbia V5C 0J3, Canada
              </Typography>
            </Box>
            <Box style={{ margin: "3rem 0 3rem 0", width: "75%", height: 250 }}>
              <GoogleMapReact
                //bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
                defaultCenter={{
                  lat: 49.284930400,
                  lng: -123.1212415,
                }}
                defaultZoom={14}
              >
                <MarkerComponent
                  lat={49.284930400}
                  lng={-123.1212415}
                  text="Vancouver Office"
                />
              </GoogleMapReact>
            </Box>
            <Box>
              <Typography
                style={{
                  margin: "0 0 2rem 0",
                  fontWeight: 500,
                 
                  color: "#5F727F",
                }}
                className={classes.fontM}
              >
                {lang.contact_us_vancouver_office[langCode]}
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                666 Burrad Street, Floor 5
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                Vancouver, British Columbia V6C 3P6, Canada
              </Typography>
            </Box>
            <Box style={{ margin: "3rem 0 3rem 0", width: "75%", height: 250 }}>
              <GoogleMapReact
                //bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
                defaultCenter={{
                  lat: 10.803209877319032,
                  lng: 106.7269929754547,
                }}
                defaultZoom={14}
              >
                <MarkerComponent
                  lat={10.803209877319032}
                  lng={106.7269929754547}
                  text={lang.contact_us_ho_office[langCode]}
                />
              </GoogleMapReact>
            </Box>
            <Box>
              <Typography
                style={{
                  margin: "0 0 2rem 0",
                  fontWeight: 500,
                 
                  color: "#5F727F",
                }}
                className={classes.fontM}
              >
                {lang.contact_us_ho_office[langCode]}
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                {lang.contact_us_ho_office_address.street[langCode]}
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                {lang.contact_us_ho_office_address.city[langCode]}
              </Typography>
              <Typography
                className={classes.fontS}
                style={{ fontWeight: 400, color: "#5F727F" }}
              >
                {lang.contact_us_ho_office_address.country[langCode]}
              </Typography>
            </Box>

            
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ color: "#5F727F" }}
          >
            <Typography
              style={{
                margin: "0 0 2rem 0",
                fontWeight: 500,
               
                color: "#3C3950",
              }}
              className={classes.fontM}
            >
              {lang.contact_us_form_title[langCode]}
            </Typography>
            <Typography
              style={{ margin: "0 0 2rem 0", fontWeight: 500}} className={classes.fontS}
            >
              {lang.contact_us_form_sub_title[langCode]}
            </Typography>
            <form style={{ margin: "0 0 2rem 0" }} onSubmit={submit}>
              <FormGroup style={{ margin: "0 0 2rem 0" }}>
                <Typography style={{ fontWeight: 500}} className={classes.fontS}>
                  {lang.contact_us_form_field_name[langCode]}
                </Typography>
                <TextField
                  type="text"
                  name="name"
                  variant="outlined"
                  value={value.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup style={{ margin: "0 0 2rem 0" }}>
                <Typography style={{ fontWeight: 500}}  className={classes.fontS}>
                  {lang.contact_us_form_field_email[langCode]}
                </Typography>
                <TextField
                  type="email"
                  name="email"
                  variant="outlined"
                  value={value.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup style={{ margin: "0 0 2rem 0" }}>
                <Typography style={{ fontWeight: 500}}  className={classes.fontS}>
                  {lang.contact_us_form_field_phone[langCode]}
                </Typography>
                <TextField
                  type="text"
                  name="phone"
                  variant="outlined"
                  value={value.phone}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup style={{ margin: "0 0 2rem 0" }}>
                <Typography style={{ fontWeight: 500}}  className={classes.fontS}>
                  {lang.contact_us_form_field_subject[langCode]}
                </Typography>
                <TextField
                  type="text"
                  name="subject"
                  variant="outlined"
                  value={value.subject}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup style={{ margin: "0 0 2rem 0" }}>
                <Typography style={{ fontWeight: 500 }}  className={classes.fontS}>
                  {lang.contact_us_form_field_message[langCode]}
                </Typography>
                <TextField
                  type="text"
                  name="message"
                  variant="outlined"
                  value={value.message}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
              </FormGroup>
              <ButtonBase
                style={{
                  margin: "0 0 2rem 0",
                  background:
                    "linear-gradient(90deg, #5F727F 26.56%, rgba(95, 114, 127, 0.29) 100%)",
                  borderRadius: 2,
                }}
                type="submit"
              >
                <Typography
                  style={{
                    padding: "0.6rem 1.5rem 0.6rem 1.5rem",
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                >
                  {lang.submit[langCode]}
                </Typography>
              </ButtonBase>
              {value.isSent == true && (
                <Typography
                  style={{ color: "#5F727F", fontSize: 18, fontWeight: 400 }}
                >
                  {lang.contact_us_form_submit_success[langCode]}
                </Typography>
              )}
              {value.isSent == false && (
                <Typography
                  style={{ color: "#D72F2F", fontSize: 18, fontWeight: 400 }}
                >
                  Sorry, please try again later.
                </Typography>
              )}
            </form>
          </Grid>
        </Grid>

        <NeedSomeHelp />
      </Container>
    </>
  );
}
// export default Navbar;
