/*
 * @Author: Dongchen Xie
 * @Date: 2020-11-01 16:04:29
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-01 12:45:13
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import useStore from "../../zustand/zustand";
import Logo from "../../images/immgration-logo-2000-566.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import lang from "../../langaguage";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navLink: {
    display: "block",
    fontSize: "1.2rem",
    color: "#343434",
    margin: "1rem",
    "&:link": {
      textDecoration: "none",
    },
    "&:visited": {
      textDecoration: "none",
    },
    "&focus": {
      textDecoration: "none",
    },
  },
  bookConsultationBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.8rem 1.5rem 0.8rem 0.8rem",
    margin: "0.4rem auto",
    width: "300px",
    maxWidth: "75vw",

    background:
      "linear-gradient(90deg,#C9A850 5% ,#FAFAFA 15% , #FAFAFA 45% ,#C9A850 60%);",
    backgroundSize: "750px 200px",
    borderRadius: "2px",
    transition: " 0.3s ease-out",
    backgroundPosition: "150px",
    "&:hover": {
      backgroundPosition: "300px",
      color: "#343434",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const navOpen = useStore((s) => s.navOpen);
  const setSurveyOpen = useStore((s) => s.setSurveyOpen);
  const setNavOpen = useStore((s) => s.setNavOpen);
  const langCode = useStore((s) => s.language);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const classes = useStyles();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getSteps() {
    return ["Personal Information", "Language", "Education", "Employment"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return "Select campaign settings...";
      case 1:
        return "What is an ad group anyways?";
      case 2:
        return "This is the bit I really care about!";
      case 3:
        return "This is the bit I really care about!";
      default:
        return "Unknown stepIndex";
    }
  }
  return (
    <div>
      <Dialog
        open={navOpen}
        TransitionComponent={Transition}
        maxWidth={"lg"}
        keepMounted
        onClose={() => {
          setNavOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            width: "800px",
            maxWidth: "100%",
            background: "linear-gradient(90deg,#3C3950 30% ,#A9B3BA 95%)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={4}>
                <img src={Logo} style={{ maxHeight: "40px", width: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={5}></Grid>
              <Grid item xs={12} sm={3}></Grid>
            </Grid>
            <IconButton
              onClick={() => {
                setNavOpen(false);
              }}
              aria-children="upload picture"
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          ></Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            children={lang.nav_home[langCode]}
            autoFocus
            className={classes.navLink}
            component={Link}
            to={`/${langCode}/`}
            onClick={() => {
              setNavOpen(false);
            }}
          />
          <Typography
            children={lang.nav_about_us[langCode]}
            component={Link}
            to={`/${langCode}/about`}
            className={classes.navLink}
            onClick={() => {
              setNavOpen(false);
            }}
          />
         
          <Typography
            children={lang.nav_bussiness[langCode]}
            component={Link}
            to={`/${langCode}/service?type=bussiness`}
            className={classes.navLink}
            onClick={() => {
              setNavOpen(false);
            }}
          />
          <Typography
            children={lang.nav_perma[langCode]}
            component={Link}
            to={`/${langCode}/service?type=perma`}
            className={classes.navLink}
            onClick={() => {
              setNavOpen(false);
            }}
          />
          <Typography
            children={lang.nav_temp[langCode]}
            component={Link}
            to={`/${langCode}/service?type=temp`}
            className={classes.navLink}
            onClick={() => {
              setNavOpen(false);
            }}
          />

          <Typography
            children={lang.nav_blog[langCode]}
            component={Link}
            to={`/${langCode}/blog`}
            className={classes.navLink}
            onClick={() => {
              setNavOpen(false);
            }}
          />
          <Typography
            children={lang.nav_contact_us[langCode]}
            component={Link}
            to={`/${langCode}/contact`}
            className={classes.navLink}
            onClick={() => {
              setNavOpen(false);
            }}
          />
        </DialogContent>
        <ButtonBase
          onClick={() => {
            setNavOpen(false);
            setSurveyOpen(true);
          }}
          className={classes.bookConsultationBtn}
        >
          <Typography style={{ fontWeight: 400, marginRight: "1.5rem" }}>
            {lang.nav_book_btn[langCode]}
          </Typography>
          <ArrowForwardIosIcon style={{ fontSize: "1.4rem" }} />
        </ButtonBase>
      </Dialog>
    </div>
  );
}
