/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 12:22:26
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-09-29 10:11:16
 * @Description: file content
 */
const lang = {
  readmore:{
      en:"Read More",
      vi:"Đọc thêm"
  },
  nav_home: {
    en: "HOME",
    vi: "TRANG CHỦ",
  },
  nav_about_us: {
    en: "ABOUT US",
    vi: "GIỚI THIỆU",
  },
  nav_service: {
    en: "SERVICE",
    vi: "DỊCH VỤ",
  },
  nav_blog: {
    en: "BLOG",
    vi: "TIN TỨC",
  },
  nav_contact_us: {
    en: "CONTACT\xa0US",
    vi: "LIÊN\xa0HỆ",
  },
  nav_bussiness: {
    en: "BUSINESS CLASS ",
    vi: "ĐỊNH CƯ ĐẦU TƯ",
  },
  nav_perma: {
    en: "PERMANENT",
    vi: "THƯỜNG TRÚ",
  },
  nav_temp: {
    en: "TEMPORARY",
    vi: "TẠM TRÚ",
  },
  nav_book_btn: {
    en: "BOOK A CONSULTATION",
    vi: " ĐẶT LỊCH TƯ VẤN ",
  },
  nav_lang_en: {
    en: "English",
    vi: "Tiếng Anh",
  },
  nav_lang_vi: {
    en: "Vietnamese",
    vi: "Tiếng Việt ",
  },
  home_banner_text: {
    en: " Your good new life starts here",
    vi: "Hành trình mới của cuộc sống tươi đẹp bắt đầu từ đây",
  },
  hone_banner_subtext: {
    en:
      "Permanent Residence | Business Immigration | LMIA & Work permit | Study & Visit",
    vi: "Thường trú | Định cư đầu tư | LMIA & Làm việc | Học tập & Du lịch",
  },
  home_banner_contact: {
    en: "CONTACT US",
    vi: "LIÊN HỆ NGAY",
  },

  home_perma_service: {
    en: "PERMANENT RESIDENCE",
    vi: "THƯỜNG TRÚ",
  },
  home_temp_service: {
    en: "TEMPORARY RESIDENCE",
    vi: "TẠM TRÚ",
  },
  home_citizen_service: {
    en: "BUSINESS IMMIGRATION",
    vi: "ĐỊNH CƯ ĐẦU TƯ",
  },
  home_about_us: {
    en: "ABOUT US",
    vi: "VỀ CHÚNG TÔI",
  },
  home_about_us_sub: {
    en: " Professional Canadian Immigration Consulting",
    vi: "TƯ VẤN ĐẦU TƯ – ĐỊNH CƯ CANADA CHUYÊN NGHIỆP",
  },

  home_about_us_text: {
    en:
      "We are a Canada-based leading consulting company providing immigration services and business consultation with a dedicated team of immigration law specialists, experienced Regulated Canadian Immigration Consultants (RCIC), members of Immigration Consultants of Canada Regulatory Council (ICCRC) with several years of combined experience in Canadian Immigration Law. We have been working with numerous clients both in Canada and worldwide to bring successful results in our clients’ pathway of study, work, investment and immigration to Canada. ",
    vi:
      "Northwestern Global Immigration là Công ty Tư vấn Di trú chuyên nghiệp hàng đầu có trụ sở hoạt động tại Canada. Với đội ngũ các chuyên gia tư vấn đầu tư và chuyên gia tư vấn luật di trú (RCIC) nhiều năm kinh nghiệm được cấp phép bởi Hội đồng Tư vấn Di trú Canada (ICCRC), chúng tôi đã giúp các khách hàng tại Canada và trên toàn thế giới, thành công trên hành trình học tập, làm việc, kinh doanh và định cư tại Canada.",
  },
  home_selling_point: [
    {
      title: {
        en: "OPPORTUNITIES",
        vi: "NẮM BẮT CƠ HỘI",
      },
      text: {
        en:
          "With many years in immigration consulting, we are well-versed with many Provincial Nomination Programs (PNP) - Entrepreneur stream. We will match your immigration profile with a best fit PNP program to optimize your chance of success.",
        vi:
          "Với nhiều năm kinh nghiệm về di trú, chúng tôi am hiểu & tư vấn các chương trình định cư vùng tỉnh bang theo dạng đầu tư phù hợp nhất với nhu cầu & khả năng của khách hàng, đem lại kết quả cao.",
      },
    },
    {
      title: {
        en: "CONNECTION",
        vi: "KẾT NỐI",
      },
      text: {
        en:
          "We match potential Canadian businesses for sale to potential buyers, and aid the buyers to conduct the purchase securely, and conveniently. Most importantly, we only connect you to the business we see fit with your entrepreneurial profile!",
        vi:
          "Chúng tôi kết nối các doanh nghiệp tiềm năng tại Canada có nhu cầu chuyển nhượng & nhượng quyền với người có nhu cầu mua, phục vụ lộ trình định cư theo diện doanh nhân một cách an toàn & thuận lợi nhất.",
      },
    },
    {
      title: {
        en: "TRUSTWORTHY",
        vi: "TIN CẬY",
      },
      text: {
        en:
          "Investors will be provided with a clear overview and pathway for their investment including consultation on incorporation of a Canadian legal entity, amalgamation or transfer of business, costs of doing business including accounting and tax preparation, restructuring business to comply with legal and immigration requirements.",
        vi:
          "Khách hàng quyết định đầu tư vào doanh nghiệp sẽ được tư vấn toàn bộ quy trình 1 cách cụ thể, minh bạch và rõ ràng, bao gồm: hỗ trợ pháp lý về thành lập công ty, sát nhập hoặc sang nhượng doanh nghiệp, chi phí thuế và khai báo thuế, tái cấu trúc doanh nghiệp phù hợp với yêu cầu hoạt động kinh doanh và luật di trú.",
      },
    },
    {
      title: {
        en: "BUSINESS OPTIMIZATION",
        vi: "TỐI ƯU HOẠT ĐỘNG DOANH NGHIỆP",
      },
      text: {
        en:
          "Support your business to obtain an LMIA (Labour Market Impact Assessment): our team of experts in business, finance and accounting, and human resources will provide you with solutions and consultations to run your business efficiently and effectively.",
        vi:
          "Hỗ trợ doanh nghiệp xin giấy phép tuyển dụng lao động từ nước ngoài (LMIA). Đội ngũ chuyên gia sẽ giúp tư vấn kinh doanh, tài chính, kế toán & nhân sự cho doanh nghiệp một cách hiệu quả.",
      },
    },
  ],
  home_need_help: {
    en: "Need some help?",
    vi: "Cần trợ giúp?",
  },
  home_need_help_phone: {
    en: "Call +1 (778)-926-9869  (In Canada)",
    vi: "Hãy gọi ngay +1 (778)-926-9869  (Tại Canada)",
  },
  home_review_title: {
    en: " TESTIMONIALS ",
    vi: " ĐÁNH GIÁ CỦA KHÁCH HÀNG",
  },
  home_reviews: [
    {
      review: {
        en:
          "I am writing to express my gratitude for Brian and his team for their assistance and guidance during my immigration application - investment stream. Brian is a well-versed immigration consultant who has expert knowledge in immigration regulations and law, and investment programs. He accurately assisted me in every steps of my immigration application process. I highly value his working attitude and professionalism and the same for his team.  I wish Brian more and more successful in his business and life. ",
        vi: "Cảm ơn Brian và team rất nhiều trong suốt thời gian qua đã luôn đồng hành và hướng dẫn mình hoàn tất hồ sơ đầu tư định cư 1 cách chính xác nhất. Brian là 1 cố vấn di trú nắm vững về luật đầu tư & định cư, hướng dẫn mình từng bước hoàn tất hồ sơ 1 cách chính xác & an toàn. Team làm việc rất chuyên nghiệp, hỗ trợ nhiệt tình và set up hồ sơ cho khách hàng nhanh chóng nhất. Tôi cực kì đánh giá cao cách làm việc của team và xin chúc Brian cùng team ngày càng thành công trong công việc cũng như trong cuộc sống.",
      },
      author: {
        en: "Customer Huynh Huy Quang, NBPNP - Entrepreneur Stream",
        vi: "Khách hàng Huỳnh Hữu Quang, Chương trình NBPNP – Diện đầu tư",
      },
    },
    {
      review: {
        en:
          "My family were very fortunate to have Brian and NGIC team to be our immigration consultant from the first day we explored the immigration pathways to Canada. The professionalism and dedication of Brian and his team helped us to obtain our visa to Canada quickly. We wish Brian and NGIC team more successful and able to help more people to immigrate to Canada.",
        vi: "Công ty rất tận tình hướng dẫn các thủ tục cần thiết và giúp khách hàng nắm rõ các quy định của từng chương trình. Tôi rất vui khi cùng gia đình nhanh chóng đến và ổn định tại Canada nhờ vào sự hỗ trợ nhiệt tình của công ty.",
      },
      author: {
        en: "Customer Mai Vinh Quý Thịnh Newfoundland and Labrador PNP",
        vi: "Khách hàng Mai Vinh Quý Thịnh Chương trình Newfoundland and Labrador PNP",
      },
    },
    {
      review: {
        en:
          "The Company (Northwestern Global Immigration) meticulously guided and assisted clients in understanding the requirements of their immigration programs. My family and I were very pleased to arrive in Canada and settle our new life here thanks to the support of the company. ",
        vi: "Gia đình chúng tôi may mắn được công ty NGIC và anh Brian tư vấn ngay từ những ngày đầu tìm hiểu về các chương trình định cư canada, cách làm việc chuyên nghiệp và tận tình của anh Brian và đội ngũ giúp chúng tôi nhanh chóng có được visa đến Canada. Chúc anh Brian và công ty NGIC luôn thành công và giúp được nhiều khách hàng hơn nữa.",
      },
      author: {
        en: "Customer Nguyen Thi Van Thi - Express Entry Skilled Worker",
        vi: "Khách hàng Nguyen Thi Van Thi - Express Entry Skilled Worker",
      },
    },
  ],

  home_contact_us_btn: {
    en: "CONTACT US",
    vi: "LIÊN HỆ NGAY",
  },
  home_lastest_news: {
    en: "LATEST NEWS",
    vi: "TIN TỨC MỚI NHẤT",
  },
  blog_banner: {
    en: "Get updated with us!",
    vi: "Cập nhật các thông tin mới nhất",
  },
  service_banner: {
    en: "Get to know our service",
    vi: "Dịch vụ của chúng tôi",
  },
  single_blog_other_recent_news: {
    en: "Other Recent News",
    vi: "Các tin tức khác",
  },
  contact_us_banner: {
    en: "Contact us",
    vi: "Thông tin liên hệ",
  },
  contact_us_title: {
    en: "GET IN TOUCH",
    vi: "LIÊN HỆ VỚI CHÚNG TÔI NGAY HÔM NAY",
  },
  contact_us_toll_free: {
    en: "Toll free: +1 (778)-926-9869  (In Canada)",
    vi: "Gọi ngay : +1 (778)-926-9869  (Tại Canada)",
  },
  contact_us_hotline: {
    en: "Hotline (Viber, Zalo, Wechat): +1 (778)-926-9869, (+84) 888 369 369 ",
    vi: "Đường dây nóng (Viber, Zalo, Wechat): +1 (778)-926-9869, (+84) 888 369 369",
  },
  contact_us_email: {
    en: "Email: info@northwesternimmigration.com",
    vi: "Email: info@northwesternimmigration.com",
  },
  contact_us_form_title: {
    en: "Get Professional Help",
    vi: "Nhận ngay hỗ trợ chuyên nghiệp",
  },
  contact_us_form_sub_title: {
    en: "Speak with us & get all of your questions answered!",
    vi:
      "Hãy để lại lời nhắn và chúng tôi sẽ liên lạc lại quý khách trong thời gian sớm nhất!",
  },
  contact_us_form_field_name: {
    en: "Name *",
    vi: "Họ và tên *",
  },
  contact_us_form_field_email: {
    en: "E-mail *",
    vi: "Địa chỉ email *",
  },
  contact_us_form_field_phone: {
    en: "Phone Number*",
    vi: "Số điện thoại*",
  },
  contact_us_form_field_subject: {
    en: "Subject *",
    vi: "Chủ đề *",
  },
  contact_us_form_field_message: {
    en: "Your Message",
    vi: "Tin nhắn của bạn",
  },
  contact_us_head_office: {
    en: "Head Office",
    vi: "Trụ sở chính",
  },
  contact_us_head_office: {
    en: "Head Office",
    vi: "Trụ sở chính",
  },
  contact_us_vancouver_office: {
    en: "Vancouver Office",
    vi: "Văn phòng tại Vancouver",
  },
  contact_us_ho_office:{
    en:"Ho Chi Minh City Representative Office",
    vi:"Văn phòng đại diện tại Thành phố Hồ Chí Minh"
  },
  contact_us_ho_office_address:{
    street:{en:"16/1 Nguyen Van Huong",vi:"16/1 Nguyễn Văn Hưởng"},
    city:{en:"Thao Dien Ward, Thu Duc City",vi:"P. Thảo Điền, TP. Thủ Đức"},
    country:{en:"Ho Chi Minh, Vietnam",vi:"TP. HCM, Việt Nam"},
  },
  contact_us_form_submit_success:{
    en: "Thank you for your information. We will contact you soon!",
    vi:"Chúng tôi đã nhận thông tin của quý khách! Công ty sẽ liên hệ với quý khách trong thời gian sớm nhất. "
  },
  about_us_banner_title: {
    en: `Professional – Dedicate – Integrity`,
    vi: "Chuyên nghiệp - Tận tâm - Chính trực",
  },
  about_us_sections: [
    // {
    //   title: {
    //     en: `Who are we?`,
    //     vi: "Về chúng tôi?",
    //   },
    //   text: {
    //     en: `Northwestern Global Immigration is a Canada-based leading consulting company providing immigration services and business consultation with a dedicated team of immigration law specialists, experienced Regulated Canadian Immigration Consultants (RCIC), members of Immigration Consultants of Canada Regulatory Council (ICCRC) with several years of combined experience in Canadian Immigration Law. We have been working with numerous clients both in Canada and worldwide to bring successful results in our clients’ pathway of study, work, investment and immigration in Canada. Our mission is to bring the best benefit to our customers through investment & settlement projects in Canada. Your new good life starts here!
    //     `,
    //     vi:
    //       "Northwestern Global Immigration là Công ty Tư vấn Di trú chuyên nghiệp hàng đầu có trụ sở hoạt động tại Canada. Với đội ngũ các chuyên gia tư vấn đầu tư và chuyên gia tư vấn luật di trú (RCIC) nhiều năm kinh nghiệm được cấp phép bởi Hội đồng Tư vấn Di trú Canada (ICCRC), chúng tôi đã giúp các khách hàng tại Canada và trên toàn thế giới, thành công trên hành trình học tập, làm việc, kinh doanh và định cư tại Canada.",
    //   },
    // },
    // {
    //   title: {
    //     en: `Who is RCIC?`,
    //     vi: "RCIC là ai?",
    //   },
    //   text: {
    //     en: `Regulated Canadian Immigration Consultants, or RCICs, are legally authorized representatives and members in good standing with the Immigration Consultants of Canada Regulatory Council (ICCRC). RCICs can represent candidates to submit application and give responses to Immigration, Refugee and Citizenship of Canada (IRCC).`,
    //     vi:
    //       "Chuyên Viên Tư vấn Luật Di Trú Canada (Regulated Canadian Immigration Consultant) là người đại diện hợp pháp, có giấy phép hành nghề tư vấn luật pháp di trú Canada và là thành viên uy tín của Hiệp Hội Cố Vấn Viên về Di trú Canada (ICCRC). RCICs có thể đại diện cho khách hàng trình nộp hồ sơ và phản hồi đến Cơ quan Di trú, Tị nạn và Quốc Tịch Canada (IRCC).",
    //   },
    // },
    {
      title: {
        en: `Who are we?`,
        vi: "Giới thiệu công ty",
      },
      text: {
        en: `Northwestern Global Immigration Corporation (NGIC) is a Canada-based immigration and business consulting company. Our team comprises experienced Regulated Canadian Immigration Consultants (RCIC), professional accountants, business consultants, and licensed realtors. We have been working with clients all over the word who wish to visit, work, invest and/or immigrate to Canada.`,
        vi:
          "Công ty Northwestern Global Immigration là công ty tư vấn di trú với trụ sở chính ở Canada. Thành viên của chúng tôi bao gồm các tư vấn di trú giàu kinh nghiệm, được cấp chứng chỉ hành nghề của Canada, những kế toán, các tư vấn kinh doanh chuyên nghiệp, và các chuyên viên địa ốc có bằng hành nghề. Chúng tôi đã và đang giúp đỡ khách hàng từ mọi nơi trên thế giới đến thăm viếng, làm việc, đầu tư và/hoặc di cư đến Canada.",
      },
    },
    {
      title: {
        en: `Our mission`,
        vi: "Sứ mệnh",
      },
      text: {
        en: `We strive to provide best value and highest quality of service to our clients. Your success is our satisfaction.`,
        vi:"Chúng tôi luôn nỗ lực không ngừng nghỉ để cung cấp dịch vụ có chất lượng tốt nhất cho khách hàng của chúng tôi. Thành công của quý khách luôn là sự hài lòng của chúng tôi.",
      },
    },
    {
      title: {
        en: `Why RCIC?`,
        vi: "Tại sao nên dùng RCIC?",
      },
      text: {
        en: `Pursuant to Canadian Immigration legislations, there are only 3 group of professionals licensed to provide immigration consultation: member in good standing of a provincial law society such as lawyer, member in good standing of Quebec’s notary public society and member in good standing of ICCRC (Immigration Consultants of Canada Regulatory Council). Consultants, including members that are not in good standing of these three organizations, are unauthorized consultants or commonly known as “ghost” consultants. Using unauthorized consultants may result in delay of application processing and/or refusal of application. RCIC’s, on the other hand, are trained individuals who have passed a national exam to become a licensed consultant. RCIC’s are in the good position to provide consultation, prepare application and/or navigate clients through the complexity of the immigration system of Canada.`,
        vi:
          "Theo luật di trú của Canada, chỉ có 3 nhóm chuyên gia có thể làm dịch vụ tư vấn di trú: thành viên của một hiệp hội luật sư của tỉnh bang, thành viên đương nhiệm của hiệp hội công chứng viên của Quebec và thành viên đương nhiệm của Hội đồng quản lý các cố vấn di trú (gọi tắt là ICCRC). Các tư vấn viên, bao gồm các thành viên không phải là thành viên đương nhiệm của 3 tổ chức này, là những tư vấn không được phép làm việc, hay còn gọi là tư vấn “ma”. Sử dụng các tư vấn không được phép làm việc sẽ có thể gây ra chậm trễ hồ sơ, và/hoặc hồ sợ bị từ chối. Mặt khác, các RCIC là những người đã được đào tạo bài bản, vượt qua kỳ thi quốc gia để trở thành tư vấn viên được cấp phép. RCIC là một trong những người phù hợp nhất để tư vấn, chuẩn bị hồ sơ và/hoặc dẫn dắt quý khách vượt qua những khó khăn, những rào cản trong hệ thống di trú của Canada.",
      },
    },
    // {
    //   title: {
    //     en: `What is ICCRC?`,
    //     vi: "ICCRC là gì?",
    //   },
    //   text: {
    //     en: `The Immigration Consultants of Canada Regulatory Council (ICCRC) is the national regulatory body designated by the government of Canada in July 2011, to regulate Canadian immigration, citizenship and international student advising services.`,
    //     vi:
    //       "Hiệp Hội Cố Vấn Viên về Di trú Canada (ICCRC) là cơ quan quản lý quốc gia do chính phủ Canada chỉ định vào tháng 7 năm 2011 để quản lý về vấn đề nhập cư, quốc tịch và dịch vụ tư vấn du học sinh tại Canada.",
    //   },
    // },
    // {
    //   title: {
    //     en: `Why do you need to hire us?`,
    //     vi: "Tại sao cần thuê chúng tôi?",
    //   },
    //   text: {
    //     en: `√ With our experienced immigration professionals, we evaluate your situation and guide you the best strategy to successfully accomplish your pathway to immigrate in Canada. For investment immigration, we navigate you with the best investment roadmap and embrace transparency.\n\n√  Free-minded and time-saving application and procedures.\n\n√  Leave professional work for a regulated consultant who has profound knowledge and be competent to Immigration Act, policies and procedures. \n\n√  Perfect the application and mitigate the risk of application delay or refusal.`,
    //     vi:
    //       "√ Với đội ngũ chuyên gia tư vấn di trú Canada được cấp phép (RCIC) nhiều năm kinh nghiệm, chúng tôi đánh giá hồ sơ và đưa ra chiến lược phù hợp nhất để thực hiện thành công con đường định cư tại Canada của quý khách. Đối với đầu tư định cư, chúng tôi định hướng lộ trình đầu tư tối ưu và cam kết sự minh bạch.\n\n√  Chúng tôi giúp khách hàng tiết kiệm thời gian và công sức về việc chuẩn bị hồ sơ, nộp đơn và các thủ tục giấy tờ cần thiết khác.\n\n√  Hãy để các cố vấn viên chịu trách nhiệm về những vấn đề chuyên môn. Chúng tôi có kiến thức sâu rộng và liên tục cập nhật các thay đổi về Bộ luật Nhập cư, các chính sách và thủ tục liên quan.\n\n√  Hoàn thiện bộ hồ sơ một cách chính xác và nhanh chóng nhất, giúp giảm thiểu rủi ro chậm trễ của quá trình nộp hồ sơ cũng như giảm thiểu khả năng bị từ chối của hồ sơ.",
    //   },
    // },
  ],
  need_some_help_title: {
    en: `Need some help?`,
    vi: "Cần trợ giúp?",
  },
  need_some_help_text: {
    en: `Please provide us some basic information. This will allow us to understand your situation, your needs and we can give you a free initial assessment on how best to start your journey successfully.`,
    vi:
      "Để giúp chúng tôi có thêm cơ sở đánh giá và hiểu được nhu cầu của quý khách, từ đó miễn phí đánh giá sơ bộ hồ sơ và tư vấn kế hoạch định cư phù hợp nhất đối với khách hàng. Vui lòng cung cấp cho chúng tôi những thông tin cơ bản.",
  },need_some_help_get_start:{
    en:"Get started by clicking the button below",
    vi:"Bắt đầu bằng cách nhấn vào nút bên dưới"
  },
  need_some_help_bookbtn: {
    en: `BOOK A CONSULTATION`,
    vi: "ĐẶT LỊCH TƯ VẤN",
  },
  sidebar_join_us: {
    en: `JOIN US NOW`,
    vi: "KẾT NỐI VỚI CHÚNG TÔI",
  },
  sidebar_text: {
    en: `Catch up on the latest news and policy changes in the Canadian Immigration world.`,
    vi:
      "Theo dõi để cập nhật nhanh các chương trình và thông tin định cư Canada mới nhất. ",
  },
  sidebar_trending: {
    en: "Trending",
    vi: "Tin tức nổi bật",
  },
  sidebar_need_help: {
    en: "Need Any Help?",
    vi: "Cần trợ giúp?",
  },
  sidebar_message1: {
    en: "Please check out our page or leave your message in ",
    vi: "Hãy để lại tin nhắn cho chúng tôi qua ",
  },
  sidebar_link: {
    en: "here",
    vi: "đây",
  },
  sidebar_or: {
    en: "OR",
    vi: "Hoặc",
  },
  sidebar_message2: {
    en: "Call us at our hotline +1 (778)-926-9869, (+84) 888 369 369",
    vi: "Gọi ngay đến đường dây nóng: +1 (778)-926-9869, (+84) 888 369 369",
  },
  footer_head_office:{
    en:"HEAD OFFICE",
    vi:"TRỤ SỞ CHÍNH"
  },
  footer_vancouver_office:{
    en:"VANCOUVER OFFICE",
    vi:"VĂN PHÒNG VANCOUVER"
  },
  footer_connect_with_us:{
    en:"CONNECT WITH US",
    vi:"KẾT NỐI VỚI CHÚNG TÔI"
  },
  seo_home:{
    en:"Northwestern Global Immigration is a Canada-based leading immigration consulting firm providing immigration services to Canada.",
    vi:"Northwestern Global Immigration tự hào là công ty tư vấn định cư Canada hàng đầu với đội ngũ chuyên gia di trú Canada RCICs chuyên nghiệp."
  },
  seo_about:{
    en:"Canadian Immigration Consultants with the head office in Vancouver. We navigate you with a personalized strategy to immigrate to Canada.",
    vi:"Công ty tư vấn định cư Canada uy tín có trụ sở hoạt động tại Canada. Chúng tôi tư vấn chiến lược định thông các chương trình định cư tỉnh bang."
  },
  seo_business:{
    en:"Canada's best immigration consulting firm, specializing in effective business immigration programs, connecting Canadian businesses.",
    vi:"Công ty tư vấn định cư Canada tốt nhất, chuyên tư vấn các chương trình định cư an toàn, hiệu quả, kết nối mua bán doanh nghiệp tại Canada. "
  },
  seo_perma:{
    en:"With our experienced immigration professionals, we guide you the best strategy to successfully accomplish your pathway to immigrate to Canada",
    vi:"Công ty tư vấn chiến lược định cư Canada hiệu quả, giúp khách hàng hoàn thiện hồ sơ định cư chính xác và nhanh chóng nhất."
  },
  seo_temp:{
    en:"Apply for a Canadian visa, a study permit or a work permit in Canada. We help you to perfect your immigration applications!",
    vi:"Cung cấp dịch vụ là hồ sơ xin visa Canada, dịch vụ cấp giấy học tập (study permit) và giấy làm việc (work permit) tại Canada."
  },
  seo_blog:{
    en:"Update with us the latest Canadian immigration news, provincial immigration programs and articles about immigration to Canada in 2020",
    vi:"Cập nhật nhanh các thông tin định cư Canada, chương trình đầu tư định cư tại tỉnh bang Canada năm 2020"
  },
  seo_contact:{
    en:"Speak with our Canadian Immigration Consultants RCICs! Get free initial assessment and get a consultation today!",
    vi:"Công ty tư vấn di trú Canada chuyên nghiệp, tận tâm, chính trực. Liên hệ với chúng tôi để được tư vấn định cư Canada ngay hôm nay!"
  },
  see_more:{
    en:"See more",
    vi:"Xem thêm"
  },
  submit:{
    en:"Submit",
    vi:"Nộp ngay"
  },
  team_members:[
    {
      name:{en:"Brian Tan Nguyen",vi:"Brian Tan Nguyen"},
      bg:{en:"M.Eng., R.C.I.C.",vi:"M.Eng., R.C.I.C."},
      title:{en:"Partner, Managing Director",vi:"Giám Đốc Điều Hành"},
      intro:{
        en:"Brian is an experienced and resourceful Regulated Canadian Immigration Consultant, an active member in good standing of the Immigration Consultants of Canada Regulatory Council (ICCRC) and a founding partner of Northwestern Global Immigration Corporation. He holds a master’s degree in engineering in Canada. Brian started his professional career as an engineer but soon found his passion in Immigration Law practice. Brian has practiced full-service immigration with expertise in Business Immigration, Economic class, and Family class. He has successfully represented hundreds of applications during his practice of immigration law.  Brian is well-versed with various immigration programs to assist clients to navigate through the immigration process. Being an entrepreneur in both Vietnam and Canada, Brian understands the life changing challenges his clients encounter in the immigration process, and he is committed to assisting them finding a good new life in Canada.",
        vi:"Ông Brian là một Cố vấn Di trú Canada có nhiều kinh nghiệm và kiến thức sâu về luật di trú. Ông là thành viên đương nhiệm của Hội đồng Quản lý Cố vấn Di trú (ICCRC) và là thành viên sáng lập của công ty Northwestern Global Immigration (NGIC). Ông có bằng Thạc Sĩ chuyên ngành Kĩ sư tại Canada.  Ông bắt đầu làm việc bằng nghề kĩ sư nhưng sau đó ông đã tìm thấy đam mê của mình trong ngành tư vấn luật Di trú. Ông Brian đã hành nghề tư vấn luật Di trú trong các chuyên môn Định cư Doanh nhân, Định cư Tay nghề hoặc Bảo lãnh thân nhân trong nhiều năm qua. Ông đã đại diện thành công cho hàng trăm hồ sơ khách hàng trong suốt thâm niên hành nghề. Là một doanh nhân ở Việt Nam và ở Canada, ông Brian thấu hiểu những khó khăn mà các khách hàng phải đối mặt trong quá trình thực hiện các hồ sơ di trú, và ông xem đây là sứ mệnh hỗ trợ những người nhập cư tìm kiếm cuộc sống tươi đẹp tại Canada."
      },
    },
    {
      name:{en:"Calvin Bui",vi:"Calvin Bui"},
      bg:{en:"B.B.A, R.C.I.C.",vi:"B.B.A, R.C.I.C."},
      title:{en:"Partner, Managing Director",vi:"Giám Đốc Phát triển Kinh doanh"},
      intro:{
        en:"Calvin is a Regulated Canadian Immigration Consultant (RCIC), active member of the Immigration Consultants of Canada Regulatory Council (ICCRC). Before joining NGIC as immigration law practitioner, Calvin has more than fifteen years of professional experience in tax, accounting, and business consulting for large organizations such as PwC, Lafarge Canada, City of Coquitlam, and numerous small and medium enterprises (SME) in Canada. With his broad spectrum of professional experience, expertise in business and immigration, Calvin has assisted numerous entrepreneurs to successfully relocate and operate their business in Canada, and simultaneously pursue their Canadian permanent residency.",
        vi:"Ông Calvin là thành viên Ban điều hành của công ty Northwestern Global Immigration Corporation (NGIC) và là Cố vấn Di trú Canada được chứng nhận, thành viên đương nhiệm của Hội đồng Quản lý các cố vấn Di trú (ICCRC). Trước khi gia nhập vào đội ngũ của NGIC, ông Calvin có hơn 15 năm kinh nghiệm hành nghề tư vấn thuế, kế toán và tư vấn kinh doanh cho các tập đoàn lớn như PwC, Lafarge Canada, Rona Revy, thành phố Coquitlam, cũng như cho nhiều doanh nghiệp vừa và nhỏ tại Canada. Với sở trường hoạt động chuyên nghiệp đa lĩnh vực, bao gồm cả xây dựng doanh nghiệp và tư vấn kinh doanh và di trú, ông Calvin đã và đang hỗ trợ cho rất nhiều doanh nhân thành công mở và vận hành doanh nghiệp tại Canada và đồng thời thực hiện lộ trình định cư tại Canada."
      },
    },
    {
      name:{en:"Anh Ho",vi:"Anh Ho"},
      bg:{en:"M.B.A., R.C.I.C.",vi:"M.B.A., R.C.I.C."},
      title:{en:"Regulated Immigration Consultant",vi:"Cố vấn Di trú chuyên nghiệp"},
      intro:{
        en:"Vinh Hoang Anh Ho (Hoang Anh) is a Regulated Canadian Immigration Consultant (RCIC), and an active member of the Immigration Consultants of Canada Regulatory Council (ICCRC). Hoang Anh has been practicing immigration since 2019. Prior to working in immigration consulting industry, Hoang Anh had more than fifteen years of business experience in business management and executive recruitment for various industries. Hoang Anh holds a master’s degree in business administration from Australia’ Royal Melbourne Institute of Technology (RMIT), a certificate in the Immigration Consultant program from Ashton College, and a bachelor’s degree in biochemistry from Arizona State University. As an R.C.I.C, Hoang Anh fulfills her passion of assisting people around the globe to immigrate to Canada through family sponsorship, temporary resident permit, as well as economic immigration programs such as Express Entry and Provincial Nomination.",
        vi:"Vinh Hoang Anh Ho (Hoang Anh) là một Cố vấn Di trú Canada được chứng nhận, thành viên đương nhiệm của Hội đồng Quản lý các cố vấn Di trú. Hoang Anh tham gia hoạt động tư vấn di trú chuyên nghiệp kể từ năm 2019. Trước đó, Hoang Anh đã có hơn 15 năm kinh nghiệm trong lĩnh vực quản trị kinh doanh và tuyển dụng nhân sự điều hành cấp cao cho các ngành nghề khác nhau. Hoang Anh có bằng Thạc Sĩ Quản trị Kinh Doanh của trường Australia’ Royal Melbourne Institute of Technology (RMIT), và bằng Cử nhân Hóa Sinh từ Đại học Arizona State University. Là một Cố vấn Di trú chuyên nghiệp, Hoang Anh hỗ trợ những người nhập cư đến định cư tại Canada, thông qua các hồ sơ bảo lãnh nhân thân, Giấy phép tạm trú cũng như các chương trình tay nghề như Express Entry và các chương trình Đề cử Tỉnh bang. "
      },
    },
    {
      name:{en:"Trent Qiao",vi:"Trent Qiao"},
      bg:{en:"M.Sc., R.C.I.C.",vi:"M.Sc., R.C.I.C."},
      title:{en:"Regulated Immigration Consultant",vi:"Regulated Immigration Consultant"},
      intro:{
        en:"Coming to Canada from China to pursue his education in 2006, Trent obtained both of his bachelor’s degree in Math and Physics, his master’s degree in computer science from UNBC. After graduation, Trent has spent several years working in real estate trading industry. While working in real estate industry, Trent has built up a strong relationship in Chinese community both domestic and abroad through which he found tremendously unmet needs for immigration assistance from foreign families. Therefore, Trent decided to become Regulated Immigration Consultant, so he can better assist more families in their quest for Canadian PR. Since obtaining his RCIC designation, he has assisted hundreds of families and students to move to Canada for a better life. Trent is fluent in both English and Chinese (Mandarin) languages.",
        vi:"Đến Canada từ Trung Quốc vào năm 2006 để du học, ông Trent có bằng Cử nhân chuyên ngành Toán Vật lý và bằng Thạc Sĩ chuyên ngành Khoa học Máy tính tại Đại học UNBC. Sau khi tốt nghiệp, ông Trent làm việc trong lĩnh vực kinh doanh bất động sản. Trong thời gian đó, ông đã tạo dựng rất nhiều mối quan hệ vững chắc trong cộng đồng người gốc Hoa tại Canada và nước ngoài, thông qua đó, ông nhận thấy được các nhu cầu rất lớn của các gia đình cần trợ giúp về luật Di trú. Do đó, ông Trent quyết định theo đuổi nghề tư vấn Di trú chuyên nghiệp nhằm hỗ trợ tốt hơn cho nhiều gia đình có nhu cầu định cư tại Canada. Từ khi hoạt động tư vấn di trú chuyên nghiệp, ông đã hỗ trợ hàng trăm gia đình và sinh viên đến sinh sống tại Canada. Ông Trent có thể giao tiếp lưu loát tiếng Anh và tiếng Trung Đại Lục."
      },
    }
  ]

};
export default lang;
