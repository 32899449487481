/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-14 09:50:08
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useStore from "../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import logoH from "../images/immgration-logo-2000-566.png";
import vi from "../images/vietnam.png";
import ca from "../images/canada.png";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../langaguage";
import homeBanner from "../images/blog-banner.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import logoV from "../images/immgration-logo-vertical-2000-1163.png";
import Carousel from "./component/carousel";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";
import parallaxImage from "../images/IMG_5358_64.jpg";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "./LoadingPage";
import moment from "moment";
import RecentNews from "./component/recentNews";
import Banner from "./component/Banner";
import SideBar from "./component/SideBar";
import facebook from "../images/facebook.png";
import {Helmet} from "react-helmet";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialMediaIcon: {
    width: "1.5rem",
    height: "1.5rem",
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",

    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  blogTitle: {
    color: "#3c3950",
    fontSize: "1.8rem",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  blogDate: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: ".5rem",
  },
  blogDate2: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: "1.5rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
}));

export default function ProminentAppBar(props) {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  let { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [news, setNews] = React.useState([]);
  const [recentNews, setRecentNews] = React.useState([]);
  const langCode = useStore((s) => s.language);
  const setEnNews = useStore((s) => s.setEnNews);
  const setViNews = useStore((s) => s.setViNews);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getNewsWithPostId = async () => {
    console.log(
      `https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs/${props.id}`
    );
    const result = await axios({
      url: `https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs/${props.id}`,
    });
    if (result.status == 200) {
      console.log(result.data);
      setNews(result.data);
      setUpTranslation(result.data.translations);
    } else {
      alert("page not found");
    }
  };
  const getNews = async () => {
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        slug: id ? id : undefined,
      },
    });
    if (result.status == 200 && result.data.length > 0) {
      console.log(result.data[0]);
      setNews(result.data[0]);
      setUpTranslation(result.data[0].translations);
    } else {
      alert("page not found");
    }
  };
  const getRecentNews = async () => {
    console.log("fetching news with lang ", langCode);
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        per_page: 3,
      },
    });
    if (result.status == 200) {
      setRecentNews(result.data);
    }
  };
  const setUpTranslation = (translation) => {
    console.log(translation);
    console.log(translation);
    if (translation.en) {
      setEnNews(translation.en);
    }
    if (translation.vi) {
      setViNews(translation.vi);
    }
  };
  const cleanTranslation = () => {
    console.log("clean translation");
    setEnNews(null);
    setViNews(null);
  };
  React.useEffect(() => {
    if (props.id) {
      getNewsWithPostId();
    } else {
      getNews();
    }
    getRecentNews();

    return cleanTranslation();
  }, [id, props.id, langCode, window.location.pathname]);
  //loading page
  if (news.length == 0) {
    return (
      <Box
        style={{
          height: "50vh",
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Loading />
      </Box>
    );
  }
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title> {news.title.rendered}</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <meta name="description" content={news.acf.description}/>
                <meta property="og:title" content={news.title.rendered}></meta>
                <meta property="og:image" content={news.acf.cover_image && news.acf.cover_image.sizes.large}></meta>
            </Helmet>
      {/* <Box className={classes.bannerContianer}>
        <Container >
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              style={{
                margin: "10vh auto 20vh auto",
                color: "#FAFAFA",
                fontWeight: 500,
                display: "inline-block",
                textAlign: "center",
              }}
              className={classes.bannerTitle}
            >
              {lang.blog_banner[langCode]}
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <ButtonBase
              component={Link}
              to={`/${langCode}/contact`}
              className={classes.contactUsBtn}
            >
              <Typography
                style={{
                  margin: "0.5rem 1rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              >
                {lang.home_banner_contact[langCode]}
              </Typography>
              <MailOutlineIcon
                style={{
                  margin: "0.5rem 0.5rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              ></MailOutlineIcon>
            </ButtonBase>
          </Box>
        </Container>
      </Box> */}
      <Banner
        image={homeBanner}
        title={lang.blog_banner[langCode]}
        // subTitle={lang.hone_banner_subtext[langCode]}
        displayBtn={true}
        contact={lang.home_banner_contact[langCode]}
      />
      <Container style={{ marginTop: "3rem" }}>
        <Grid container justify="space-around">
          <Grid item xs={12} md={7}>
            <Typography className={classes.blogTitle}>
              {news.title.rendered}
              
            </Typography>
            <Typography className={classes.blogDate}>
              {moment(news.modified).format("MMMM Do YYYY")} by Northwestern
              Immigration
            </Typography>
            <Box>
           
            </Box>
            <Box>
              <Typography
                className={classes.blogDate2}
                style={{
                  textOverflow: "ellipsis",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                  color: "#3c3950",
                }}
              >
                {news.acf.description}
              </Typography>
            </Box>
            

            <img
              style={{ width: "100%" }}
              src={news.acf.cover_image && news.acf.cover_image.sizes.large}
            />
            <Box>
              <div
                id="news_post"
                style={{ overflow: "auto", color: "#73838F" ,userSelect:"none"}}
                dangerouslySetInnerHTML={{ __html: news.acf.contentc }}
              ></div>
            </Box>
            <RecentNews />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box style={{ position: "sticky" }}>
              <SideBar />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* <Container style={{ marginTop: "3rem" ,marginBottom:"3rem"}}>
        <Typography className={classes.blogTitle}>
          {lang.single_blog_other_recent_news[langCode]}
        </Typography>
        {recentNews.length > 0 ? (
          recentNews.map((v,i) => {
            return (
              <>
                <Typography
                  className={classes.blogTitle}
                  style={{ fontSize: "1.2rem" }}
                >
                  {v.title.rendered}
                </Typography>
                <Typography className={classes.blogDate} style={{marginBottom: "1.2rem" }}>
                  {moment(v.modified).format("MMMM Do YYYY")} by Northwestern
                  Immigration
                </Typography>
                <Typography
                  component={Link}
                  to={`/${langCode}/blog/${v.slug}`}
                  className={classes.serviceText}
                  style={{marginBottom:"1rem"}}
                >
                  Read More ...
                </Typography>
                {i<recentNews.length-1&& <hr/>}
               
              </>
            );
          })
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "200px",
            }}
          >
            <Loading />
          </Box>
        )}
      </Container> */}
    </>
  );
}
// export default Navbar;
