/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-21 16:24:07
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import useStore from "../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import logoH from "../images/immgration-logo-2000-566.png";
import vi from "../images/vietnam.png";
import ca from "../images/canada.png";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../langaguage";
import homeBanner from "../images/service-banner.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import logoV from "../images/immgration-logo-vertical-2000-1163.png";
import Carousel from "./component/carousel";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";
import parallaxImage from "../images/IMG_5358_64.jpg";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "./LoadingPage";
import moment from "moment";
import ServiceSelector from "./component/serviceSelector";
import NeedSomeHelp from "./component/needSomeHelp";
import Fab from "@material-ui/core/Fab";
import Banner from "./component/Banner";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",
    marginTop: "4rem",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    "&:hover": {
      backgroundColor: "rgba(201,168,80,0.9)",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  blogTitle: {
    color: "#3c3950",
    fontSize: "1.8rem",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  blogDate: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: "3rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ProminentAppBar(props) {
  let query = useQuery();
  const classes = useStyles();
  const blogRef = React.useRef();
  let { path, url } = useRouteMatch();
  let { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [news, setNews] = React.useState([]);
  const [recentNews, setRecentNews] = React.useState([]);
  const langCode = useStore((s) => s.language);
  const setEnNews = useStore((s) => s.setEnNews);
  const setViNews = useStore((s) => s.setViNews);
  const [serviceSEO, setServiceSEO] = React.useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getNewsWithPostId = async () => {
    console.log(
      `https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services/${query.get(
        "id"
      )}`
    );
    const result = await axios({
      url: `https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services/${query.get(
        "id"
      )}`,
    });
    if (result.status == 200) {
      console.log(result.data);
      setNews(result.data);
      setUpTranslation(result.data.translations);
    } else {
      alert("page not found");
    }
  };
  const getDefaultNews = async (type) => {
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        lang: langCode,
        service_type: type,
      },
    });
    if (result.status == 200 && result.data.length > 0) {
      console.log(result.data[0]);
      setNews(result.data[0]);
      setUpTranslation(result.data[0].translations);
    } else {
      alert("page not found");
    }
  };
  const getNews = async () => {
    console.log("service id", id);
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        slug: id,
      },
    });
    if (result.status == 200 && result.data.length > 0) {
      console.log(result.data[0]);
      setNews(result.data[0]);
      setUpTranslation(result.data[0].translations);
    } else {
      alert("page not found");
    }
  };
  const getRecentNews = async () => {
    console.log("fetching news with lang ", langCode);
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        lang: langCode,
        per_page: 3,
      },
    });
    if (result.status == 200) {
      setRecentNews(result.data);
    }
  };
  const setUpTranslation = (translation) => {
    console.log(translation);
    console.log(translation);
    if (translation.en) {
      setEnNews(translation.en);
    }
    if (translation.vi) {
      setViNews(translation.vi);
    }
  };
  const cleanTranslation = () => {
    console.log("clean translation");
    setEnNews(null);
    setViNews(null);
  };

  React.useEffect(() => {
    console.log("id:", id);
    console.log("id:", props.id);
    if (query.get("id")) {
      getNewsWithPostId();
    } else if (id) {
      // alert(id)
      getNews();
    } else {
      if (query.get("type") == "bussiness") {
        getDefaultNews(75);
        setServiceSEO (
          <Helmet>
            <meta charSet="utf-8" />

            <meta name="description" content={lang.seo_business[langCode]} />
          </Helmet>
        );
      } else if (query.get("type") == "temp") {
        getDefaultNews(74);
        setServiceSEO (
          <Helmet>
            <meta charSet="utf-8" />

            <meta name="description" content={lang.seo_temp[langCode]} />
          </Helmet>
        );
      } else if (query.get("type") == "perma") {
        getDefaultNews(73);
        setServiceSEO (
          <Helmet>
            <meta charSet="utf-8" />

            <meta name="description" content={lang.seo_perma[langCode]} />
          </Helmet>
        );
      }
    }
    getRecentNews();

    return cleanTranslation();
  }, [
    id,
    props.id,
    langCode,
    window.location.pathname,
    query.get("type"),
    query.get("id"),
  ]);
  React.useEffect(() => {
    if (news.length != 0) {
      const y =
        document.getElementById("article").getBoundingClientRect().top +
        window.scrollY -
        160;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    }
  }, [news]);
  React.useEffect(() => {
    if (news.length != 0) {
      const y =
        document.getElementById("article").getBoundingClientRect().top +
        window.scrollY -
        160;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    }
  }, [news]);
  //loading page
  // if (news.length == 0) {
  //   return (
  //     <Box
  //       style={{
  //         height: "50vh",
  //         display: "flex",
  //         width: "100%",
  //         justifyContent: "space-around",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Loading />
  //     </Box>
  //   );
  // }
  return (
    <>
    {serviceSEO}
      {/* <Box className={classes.bannerContianer}>
        <Container>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              style={{
                margin: "10vh auto 20vh auto",
                color: "#FAFAFA",
                fontWeight: 500,
                display: "inline-block",
                textAlign: "center",
              }}
              className={classes.bannerTitle}
            >
              {lang.service_banner[langCode]}
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <ButtonBase
              component={Link}
              to={`/${langCode}/contact`}
              className={classes.contactUsBtn}
            >
              <Typography
                style={{
                  margin: "0.5rem 1rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              >
                {lang.home_banner_contact[langCode]}
              </Typography>
              <MailOutlineIcon
                style={{
                  margin: "0.5rem 0.5rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              ></MailOutlineIcon>
            </ButtonBase>
          </Box>
        </Container>
      </Box> */}
      <Banner
        image={homeBanner}
        title={lang.service_banner[langCode]}
        // subTitle={lang.hone_banner_subtext[langCode]}
        displayBtn={true}
        contact={lang.home_banner_contact[langCode]}
      />
      <Container style={{ marginTop: "3rem", marginBottom: "1.5rem" }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <ServiceSelector />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{ borderRadius: "5px", border: "2px soild rgb(201,168,80)" }}
          >
            <Box
              id={"article"}
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(201,168,80)",
                padding: "2rem",
              }}
            >
              <Typography className={classes.blogTitle}>
                {news.title ? news.title.rendered : "loading"}
              </Typography>
              <Typography className={classes.blogDate}>
                {moment(news.modified).format("MMMM Do YYYY")} by Northwestern
                Immigration
              </Typography>
              <Box>
                <div
                  id="news_post"
                  style={{ overflow: "auto", color: "#73838F" }}
                  dangerouslySetInnerHTML={{
                    __html: news.acf ? news.acf.contentc : "loading",
                  }}
                ></div>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <NeedSomeHelp />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
// export default Navbar;
