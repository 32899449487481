/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-21 16:27:00
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
  useLocation
} from "react-router-dom";
import useStore from "../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import logoH from "../images/immgration-logo-2000-566.png";
import vi from "../images/vietnam.png";
import ca from "../images/canada.png";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../langaguage";
import { ExpandLessSharp } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import Survey from "../pages/component/Survey";
import NavPopup from "../pages/component/navPopup";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bookConsultationBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.6rem 1.5rem 0.6rem 0.8rem",
    margin: "0.4rem 2rem 0.4rem 0",
    background:
      "linear-gradient(90deg,#C9A850 5% ,#FAFAFA 15% , #FAFAFA 45% ,#C9A850 60%);",
    backgroundSize: "750px 200px",
    borderRadius: "2px",
    transition: " 0.3s ease-out",
    backgroundPosition: "150px",
    "&:hover": {
      backgroundPosition: "300px",
      color: "#343434",
    },
  },
  logo: {
   
    width: "250px",
    maxWidth: "30vw",
    margin: "0.5rem",
    [theme.breakpoints.down("md")]: {
      width: "160px"
    },
    
  },
}));
const Navbar = () => {
  const langCode = useStore((s) => s.language);
  return (
    <div>
      <Link to={"/en"}>English</Link>
      <Link to={"/vi"}>Vietnamese</Link>
      <Link to={`/${langCode}/page`}>Normal Page</Link>
    </div>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "100px",
    height: 10,
    "&": {
      marginTop: "-20",
      height: 3,
    },
    "& > span": {
      maxWidth: 45,
      width: "100%",
      backgroundColor: "rgb(201,168,80)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#343434",

    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "0.8rem",
    fontWeight: 500,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    margin: 0,
    padding: 0,
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "rgb(201,168,80)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgb(201,168,80)",
    },

    minWidth:  "auto" , // a number of your choice
    width:  "auto" ,
    maxWidth: "auto", 
    padding:"0 0.5rem",// a number of your choice
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
      // minWidth: 100, // a number of your choice
      // width: 100,
      // maxWidth: 100,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const history = useHistory();
  console.log(window.location.pathname.split("/"));
  const [value, setValue] = React.useState(0);
  const [enLink, setEnLink] = React.useState("");
  const [viLink, setViLink] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const langCode = useStore((s) => s.language);
  const enNews = useStore((s) => s.enNews);
  const viNews = useStore((s) => s.viNews);
  const setEnNews = useStore((s) => s.setEnNews);
  const setViNews = useStore((s) => s.setViNews);
  const setSurveyOpen = useStore((s) => s.setSurveyOpen);
  const setNavOpen = useStore((s) => s.setNavOpen);
  let query = useQuery();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const clearTranslation = () => {
    console.log("nav clear translation");
    setEnNews(null);
    setViNews(null);
  };
  React.useEffect(() => {
    console.log("en vi new code changed");
    console.log("en", enNews);
    console.log("vi", viNews);
    if (enNews) {
      setEnLink(`/en/${window.location.pathname.split("/")[2]}?id=${enNews}`);
    } else {
      setEnLink(`/en/${window.location.pathname.split("/")[2]}`);
    }
    if (viNews) {
      setViLink(`/vi/${window.location.pathname.split("/")[2]}?id=${viNews}`);
    } else {
      setViLink(`/vi/${window.location.pathname.split("/")[2]}`);
    }

    if (!window.location.pathname.split("/")[2]) {
      setViLink(`/vi/`);
      setEnLink(`/en/`);
    } else if (window.location.pathname.split("/")[2] == "about") {
      setValue(1);
    } else if (window.location.pathname.split("/")[2] == "service") {
      
      if (query.get("type") == "bussiness") {
        setValue(2);
      } else if (query.get("type") == "temp") {
        setValue(4);
      } else if (query.get("type") == "perma") {
        setValue(3);
      }else{
        setValue(2);
      }
     
    } else if (window.location.pathname.split("/")[2] == "blog") {
      setValue(5);
    } else if (window.location.pathname.split("/")[2] == "contact") {
      setValue(6);
    }
  }, [enNews, viNews, window.location.pathname,query.get("type")]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    clearTranslation();
  }, [window.location.pathname]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const search = (e) => {
    e.preventDefault();
    console.log(e.target.query.value);
    history.push(`/${langCode}/search?query=${e.target.query.value}`);
    handleClose();
  };

  return (
    <>
      <NavPopup />
      <Survey />
      {/* moblie nav */}
      <AppBar
        position="sticky"
        color={grey[50]}
        elevation={0}
        className={classes.mobileNavControl}
        id="nav1"
      >
        <nav>
        <Box style={{ margin: "auto" }} onClick={()=>{window.location.href("/")}}>
          <img
            
            src={logoH}
            style={{ width: "240px", maxWidth: "30vw", margin: "0.5rem" }}
          />
        </Box>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            onClick={() => {
              setNavOpen(true);
            }}
            style={{ display: "flex" }}
          >
            <Typography
              style={{
                fontWeight: 450,
                fontSize: "0.9rem",
                margin: "auto 1rem",
              }}
            >
              MENU
            </Typography>
            <IconButton
              onClick={() => {
                setNavOpen(true);
              }}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon style={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton component={Link} to={viLink}>
              <Tooltip title="Vietnamese">
                <img src={vi} style={{ height: "1.5rem", width: "1.5rem" }} />
              </Tooltip>
            </IconButton>
            <IconButton component={Link} to={enLink}>
              <Tooltip title="English">
                <img src={ca} style={{ height: "1.5rem", width: "1.5rem" }} />
              </Tooltip>
            </IconButton>
          </Box>
        </Toolbar>
        </nav>
      </AppBar>
      {/* desktop nav */}
      <AppBar
        position="sticky"
        color={grey[50]}
        elevation={0}
        className={classes.desktopNavControl}
      >
        <nav>
        <Box style={{ display: "flex" }}>
          <Box style={{ margin: "1.2rem" }}>
            <img
              src={logoH}
              className={classes.logo}
              
            />
          </Box>
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginRight: "3.5rem",
              }}
            >
              <Box>
                <IconButton component={Link} to={viLink}>
                  <Tooltip title={lang.nav_lang_vi[langCode]}>
                    <img
                      src={vi}
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton component={Link} to={enLink}>
                  <Tooltip title={lang.nav_lang_en[langCode]}>
                    <img
                      src={ca}
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    />
                  </Tooltip>
                </IconButton>
              </Box>
              <ButtonBase
                onClick={() => {
                  setSurveyOpen(true);
                }}
                className={classes.bookConsultationBtn}
              >
                <Typography style={{ fontWeight: 500, marginRight: "1.5rem" }}>
                  {lang.nav_book_btn[langCode]}
                </Typography>
                <ArrowForwardIosIcon style={{ fontSize: "1.4rem" }} />
              </ButtonBase>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "1rem 2.5rem auto 0",
              }}
            >
              <IconButton aria-describedby={id} onClick={handleClick}>
                <Tooltip title="Seach">
                  <SearchIcon style={{ fontSize: "2.5rem" }} />
                </Tooltip>
              </IconButton>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
                style={{ margin: "auto 0" }}
                TabIndicatorProps={{
                  style: {
                    height: "10px",
                  },
                }}
              >
                <StyledTab
                  label={lang.nav_home[langCode]}
                  autoFocus
                  component={Link}
                  to={`/${langCode}/`}
                  {...a11yProps(0)}
                />
                <StyledTab
                  label={lang.nav_about_us[langCode]}
                  component={Link}
                  to={`/${langCode}/about`}
                  {...a11yProps(1)}
                />
                <StyledTab
                  label={lang.nav_bussiness[langCode]}
                  component={Link}
                  to={`/${langCode}/service?type=bussiness`}
                  {...a11yProps(2)}
                />
                <StyledTab
                  label={lang.nav_perma[langCode]}
                  component={Link}
                  to={`/${langCode}/service?type=perma`}
                  {...a11yProps(3)}
                />
                <StyledTab
                  label={lang.nav_temp[langCode]}
                  component={Link}
                  to={`/${langCode}/service?type=temp`}
                  {...a11yProps(4)}
                />

                <StyledTab
                  label={lang.nav_blog[langCode]}
                  component={Link}
                  to={`/${langCode}/blog`}
                  {...a11yProps(5)}
                />
                <StyledTab
                  label={lang.nav_contact_us[langCode]}
                  component={Link}
                  to={`/${langCode}/contact`}
                  {...a11yProps(6)}
                />
              </StyledTabs>
            </Box>
          </Box>
        </Box>
        </nav>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginTop: "1rem" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box style={{ padding: "1rem" }}>
          <form onSubmit={search}>
            <TextField
              label="Search"
              variant="outlined"
              color="primary"
              name="query"
            ></TextField>
            <input type="submit" hidden></input>
          </form>
        </Box>
      </Popover>
    </>
  );
}
// export default Navbar;
