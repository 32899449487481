/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-21 15:54:53
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import useStore from "../../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "../../langaguage";
import homeBanner from "../../images/home-banner-2000-1333.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "../LoadingPage";
import $ from "jquery";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",

    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginRight: "auto",
    color: "#FAFAFA",

    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  centerContainer: {
    // display: "flex",
    // justifyContent: "space-around",
    // [theme.breakpoints.down("xs")]: {
    //     display: "block",
    // },
  },
  centerContainerFixed: {
    // display: "flex",
    // justifyContent: "space-around",
  },
  serviceTitle: {
    padding: "0.5rem 1rem 0.5rem 1rem",
    color: "#FAFAFA",
    fontSize: "1.2rem",
    backgroundColor: "#C9A850",
    fontWeight: 500,
  },
  serviceText: {
    color: "#5f727F",
    backgroundColor: "#F5F5F5",
    fontSize: "1.2rem",
  },
  serviceList: {
    padding: "0.5rem 1rem",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "space-around",
    minHeight: "100px",
    alignItems: "center",
  },
  linkRemoveStyle: {
    "&:link": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:active": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:visited": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:hover": {
      color: "#C9A850" /* blue colors for links too */,
      textDecoration: "inherit",
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ProminentAppBar() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const [services, setServices] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const langCode = useStore((s) => s.language);
  const [expanded, setExpanded] = React.useState({
    0: true,
    1: false,
    2: false,
  });
  let query = useQuery();
  const handleExpandClick = (num) => {
    setExpanded((prev) => {
      return { 0: false, 1: false, 2: false, [num]: true };
    });
    console.log(expanded);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getService = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url:
        "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/services",
      params: {
        lang: langCode,
        per_page: 100,
      },
    });
    if (result.status == 200) {
      setServices(result.data);
    }
  };
  const getNews = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        per_page: 3,
      },
    });
    if (result.status == 200) {
      setNews(result.data);
    }
  };
  React.useEffect(() => {
    getService();
  }, [langCode]);
  React.useEffect(() => {
    if (query.get("type") == "bussiness") {
      handleExpandClick(2);
    } else if (query.get("type") == "temp") {
      handleExpandClick(1);
    } else if (query.get("type") == "perma") {
      handleExpandClick(0);
    }
  }, [query.get("type")]);
  React.useEffect(() => {
    console.log("!!!!!!!!herhehrehehrherhehrhhehrehhrwerwerewrwrwerwerwerh");
    var $window = $(window);
    $(window).resize(execute);

    $window.on("scroll", execute);
    execute();
    function execute() {
      console.log("oh");
      if ($(window).width() < 960) {
        return;
      }
      var position = $window.scrollTop();
      if(!$(`#article`).offset()){
        return
      }
      if(position < $(`#article`).offset().top-160){
       
        $(`#service-selector`).css("margin-top",`${0}px`)
      } else if (position >= $(`#article`).offset().top-160&&position<= $(`#article`).height()+$(`#article`).offset().top-$( window ).height()/2) {
         
        $(`#service-selector`).css("margin-top",`${position - $(`#article`).offset().top+160}px`)
      } 
    }
  }, []);
  return (
    <>
      <Container style={{ paddingBottom: "2rem" }} id="service-selector">
        <Grid container>
          <Grid xs={12}>
            <Box className={classes.centerContainer} >
              <Box>
                <Box
                  className={classes.serviceTitle}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #DECB98",
                  }}
                >
                  <Typography className={classes.serviceTitle}>
                    {lang.home_perma_service[langCode]}
                  </Typography>
                  <Box>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded[0],
                      })}
                      onClick={() => {
                        handleExpandClick(0);
                      }}
                      aria-expanded={expanded[0]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Collapse in={expanded[0]} timeout="auto" unmountOnExit>
                  <Box className={classes.serviceText}>
                    {services.length > 0 ? (
                      services
                        .filter((v) => {
                          if (v.service_type) {
                            return v.service_type.indexOf(73) > -1;
                          }
                          return false;
                        })
                        .map((v) => {
                          return (
                            <Box className={classes.serviceList}>
                              <Typography
                                className={classes.linkRemoveStyle}
                                component={Link}
                                to={`/${langCode}/service/${v.slug}?type=perma`}
                              >
                                •&nbsp;&nbsp;{v.title.rendered}
                              </Typography>
                            </Box>
                          );
                        })
                    ) : (
                      <Box className={classes.loadingContainer}>
                        <Loading />
                      </Box>
                    )}
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className={classes.centerContainer}>
              <Box>
                <Box
                  className={classes.serviceTitle}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #DECB98",
                  }}
                >
                  <Typography className={classes.serviceTitle}>
                    {lang.home_temp_service[langCode]}
                  </Typography>
                  <Box>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded[1],
                      })}
                      onClick={() => {
                        handleExpandClick(1);
                      }}
                      aria-expanded={expanded[1]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Collapse in={expanded[1]} timeout="auto" unmountOnExit>
                  <Box className={classes.serviceText}>
                    {services.length > 0 ? (
                      services
                        .filter((v) => {
                          if (v.service_type) {
                            return v.service_type.indexOf(74) > -1;
                          }
                          return false;
                        })
                        .map((v) => {
                          return (
                            <Box className={classes.serviceList}>
                              <Typography
                                className={classes.linkRemoveStyle}
                                component={Link}
                                to={`/${langCode}/service/${v.slug}?type=temp`}
                              >
                                •&nbsp;&nbsp;{v.title.rendered}
                              </Typography>
                            </Box>
                          );
                        })
                    ) : (
                      <Box className={classes.loadingContainer}>
                        <Loading />
                      </Box>
                    )}
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Grid>

          <Grid xs={12}>
            <Box className={classes.centerContainer}>
              <Box>
                <Box
                  className={classes.serviceTitle}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography className={classes.serviceTitle}>
                    {lang.home_citizen_service[langCode]}
                  </Typography>
                  <Box>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded[2],
                      })}
                      onClick={() => {
                        handleExpandClick(2);
                      }}
                      aria-expanded={expanded[2]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Collapse in={expanded[2]} timeout="auto" unmountOnExit>
                  <Box className={classes.serviceText}>
                    {services.length > 0 ? (
                      services
                        .filter((v) => {
                          if (v.service_type) {
                            return v.service_type.indexOf(75) > -1;
                          }
                          return false;
                        })
                        .map((v) => {
                          return (
                            <Box className={classes.serviceList}>
                              <Typography
                                className={classes.linkRemoveStyle}
                                component={Link}
                                to={`/${langCode}/service/${v.slug}?type=business`}
                              >
                                •&nbsp;&nbsp;{v.title.rendered}
                              </Typography>
                            </Box>
                          );
                        })
                    ) : (
                      <Box className={classes.loadingContainer}>
                        <Loading />
                      </Box>
                    )}
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
// export default Navbar;
