/*
 * @Author: Dongchen Xie
 * @Date: 2020-10-12 08:21:44
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-02-07 21:22:45
 * @Description: file content
 */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import useStore from "./../zustand/zustand";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import lang from "./../langaguage";
import homeBanner from "./../images/blog-banner.jpg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import axios from "axios";
import { Grid } from "@material-ui/core";
import { Parallax, Background } from "react-parallax";

import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Loading from "./LoadingPage";
import moment from "moment";
import SingleBlog from "../pages/SingleBlog";
import SideBar from "./component/SideBar";
import Pagination from "@material-ui/lab/Pagination";
import Banner from "./component/Banner";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileNavControl: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  desktopNavControl: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactUsBtn: {
    color: "white",
    fontWeight: 600,
    padding: "0.2rem 1rem 0.2rem 0.6rem",

    border: "3px solid #FAFAFA",

    backgroundSize: "750px 300px",
    marginTop: "4rem",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    "&:hover": {
      backgroundColor: "rgba(201,168,80,0.9)",
    },
  },
  bannerContianer: {
    minHeight: "500px",
    background: `url(${homeBanner}) no-repeat  center`,
    backgroundSize: "cover",
    height: "60vh",
  },
  bannerTitle: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  centerContainer: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  centerContainerFixed: {
    display: "flex",
    justifyContent: "space-around",
  },
  blogTitle: {
    color: "#3c3950",
    fontSize: "1.8rem",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  blogDate: {
    color: "#5F727F",
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: "3rem",
  },
  serviceText: {
    color: "#5f727F",

    fontSize: "1.2rem",
  },
  serviceList: {
    margin: "0.5rem 0",
  },
  contactUsBtn2: {
    color: "white",
    fontWeight: 600,
    padding: "0.7rem 1.8rem 0.7rem 1.8rem",

    border: "3px solid #FAFAFA", //dsf

    backgroundSize: "750px 300px",
    backgroundColor: "#C9A850",
    transition: " 0.3s ease-out",
    backgroundPosition: "0px 150px",
    fontSize: "1rem",
    "&:hover": {
      color: "#343434",
    },
  },
  removeStyle:{
    "&:link": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:active": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:visited": {
      color: "inherit" /* blue colors for links too */,
      textDecoration: "inherit",
    },
    "&:hover": {
      color: "#C9A850" /* blue colors for links too */,
      textDecoration: "inherit",
    },
  }
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ProminentAppBar(props) {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  let { id } = useParams();
  let query = useQuery();
  let history = useHistory();
  const [news, setNews] = React.useState([]);
  const [recentNews, setRecentNews] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const setEnNews = useStore((s) => s.setEnNews);
  const setViNews = useStore((s) => s.setViNews);
  const handleChange = (event, value) => {
    console.log("change page", parseInt(value));
    setPage(parseInt(value));
  };
  const langCode = useStore((s) => s.language);

  const getRecentNews = async () => {
    if (!langCode) {
      return;
    }
    const result = await axios({
      url: "https://wordpress.northwesternimmigration.com/wp-json/wp/v2/blogs",
      params: {
        lang: langCode,
        per_page: 6,
        page: query.get("page") ? query.get("page") : 1,
      },
    });
    if (result.status == 200) {
      setRecentNews(result.data);
      console.log(result);
      setTotalPage(result.headers["x-wp-totalpages"]);
    }
  };

  React.useEffect(() => {
    setRecentNews([]);
    getRecentNews();
  }, [langCode, query.get("page")]);
  React.useEffect(() => {
    if (!query.get("id")) {
      console.log(page);
      history.push(`${window.location.pathname}?page=${page}`);
    }
  }, [page]);
  //loading page
  if (query.get("id")) {
    console.log("single blog");
    return <SingleBlog id={query.get("id")} />;
  } else {
    setEnNews(null);
    setViNews(null);
  }
  return (
    <>
      <Helmet>
            <meta charSet="utf-8" />

            <meta name="description" content={lang.seo_blog[langCode]} />
          </Helmet>
      {/* <Box className={classes.bannerContianer}>
        <Container>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              style={{
                margin: "10vh auto 20vh auto",
                color: "#FAFAFA",
                fontWeight: 500,
                display: "inline-block",
                textAlign: "center",
              }}
              className={classes.bannerTitle}
            >
              {lang.blog_banner[langCode]}
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <ButtonBase
              component={Link}
              to={`/${langCode}/contact`}
              className={classes.contactUsBtn}
            >
              <Typography
                style={{
                  margin: "0.5rem 1rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              >
                {lang.home_banner_contact[langCode]}
              </Typography>
              <MailOutlineIcon
                style={{
                  margin: "0.5rem 0.5rem 0.5rem 0.5rem",

                  fontWeight: 500,
                  display: "inline-block",
                }}
              ></MailOutlineIcon>
            </ButtonBase>
          </Box>
        </Container>
      </Box> */}
      <Banner
        image={homeBanner}
        title={lang.blog_banner[langCode]}
        // subTitle={lang.hone_banner_subtext[langCode]}
        displayBtn={true}
        contact={lang.home_banner_contact[langCode]}
      />
      <Container
        style={{ marginTop: "3rem", marginBottom: "3rem", minHeight: "30vh" }}
      >
        <Grid container justify="space-around">
          <Grid item xs={12} md={7}>
            {recentNews.length > 0 ? (
              recentNews.map((v, i) => {
                return (
                  <Box
                  className={classes.removeStyle}
                    component={Link}
                    to={`/${langCode}/blog/${v.slug}`}
                    style={{ marginBottom: "3rem" }}
                  >
                    <Typography
                      className={classes.blogTitle}
                      style={{ fontSize: "1.2rem" }}
                    >
                      {v.title.rendered}
                    </Typography>
                    <Typography
                      className={classes.blogDate}
                      style={{ marginBottom: "1.2rem" }}
                    >
                      {moment(v.modified).format("MMMM Do YYYY")} by
                      Northwestern Immigration
                    </Typography>
                    <Grid container justify="space-between">
                      <Grid item xs={12} md={4}>
                        <Box
                          style={{
                            backgroundImage: `url(${
                              v.acf.cover_image && v.acf.cover_image.sizes.large
                            })`,
                            minWidth: "250px",
                            minHeight: "250px",
                            maxWidth: "50vw",
                            margin: "0 auto",
                            marginBottom: "2rem",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></Box>
                      </Grid>
                      <Grid item item xs={12} md={7}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <Box>
                            <Typography style={{ textOverflow: "ellipsis" }}>
                              {v.acf.description}
                            </Typography>
                          </Box>

                          <Typography
                            component={Link}
                            to={`/${langCode}/blog/${v.slug}`}
                            className={classes.serviceText}
                            style={{
                              fontSize: "0.8rem",
                              marginBottom: "1rem",
                              textAlign: "right",
                              marginTop: "1rem",
                              marginBottom: "4rem",
                            }}
                          >
                            {lang.readmore[langCode]} ...
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  height: "200px",
                }}
              >
                <Loading />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <SideBar />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-around">
          <Pagination
            count={totalPage}
            variant="outlined"
            shape="rounded"
            page={query.get("page") ? parseInt(query.get("page")) : 1}
            onChange={handleChange}
          />
        </Box>
      </Container>
    </>
  );
}
// export default Navbar;
